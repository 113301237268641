import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface ISurvey {
  docId: string;
  createdAt: Timestamp;
  email: string;
  leadName: string;
  mobile: string;
  pincode: string;
  profession: string;
  question1: string;
  question2: string;
  question3: string;
  question4: string;
  question5: string;
  question6: string;
  question7: string;
  updatedAt: Timestamp;
}

export interface SurveyReports {
  surveyReports: ISurvey[];
}
const initialState: SurveyReports = {
  surveyReports: [],
};

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setSurveyReports: (
      state,
      action: PayloadAction<SurveyReports['surveyReports']>
    ) => {
      state.surveyReports = action.payload;
    },
  },
});

export const { setSurveyReports } = surveySlice.actions;

export default surveySlice.reducer;
