import { Image, View } from '@react-pdf/renderer';

export const WaterMark = () => {
  return (
    <View
      style={{
        position: 'absolute',
        top: '40%',
        left: '30%',
        // transform: 'rotate(-45deg)',
        opacity: 0.2,
      }}
      fixed
    >
      <Image
        src={require('../../logo.png')}
        style={{
          width: 250,
          height: 250,
        }}
      />
    </View>
  );
};
