import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IBoosterKit {
  uid: string;
  name: string;
  file: File;
  roles: string[];
  departments: string[];
  subDepartments: string[];
  status: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface File {
  type: string;
  url: string;
}

interface BoosterKitState {
  boosterKits: IBoosterKit[];
  boosterPosts: IBoosterKit[];
}

const initialState: BoosterKitState = {
  boosterKits: [],
  boosterPosts: [],
};

export const boosterKitsSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setBoosterKits(
      state,
      action: PayloadAction<BoosterKitState['boosterKits']>
    ) {
      state.boosterKits = action.payload;
    },
    setBoosterPosts(
      state,
      action: PayloadAction<BoosterKitState['boosterPosts']>
    ) {
      state.boosterPosts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBoosterKits, setBoosterPosts } = boosterKitsSlice.actions;

export default boosterKitsSlice.reducer;
