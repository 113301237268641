import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IFeedback {
  docId: string;
  communication: number;
  createdAt: string;
  remark: string;
  technical: number;
  updatedAt: string;
}

interface Participant {
  displayName: string;
  email: string;
}

interface Guest {
  currentCtc: string;
  currentRole: string;
  displayName: string;
  email: string;
  expectedCtc: string;
  experience: string;
  mobile: string;
  noticePeriod: string;
  resume: string;
}

export interface IMeetings {
  docId: string;
  createdBy: string;
  attachment: string;
  description: string;
  endTime: string;
  guests: Guest[];
  participants: Participant[];
  reminder: string;
  scheduleDate: Timestamp;
  startTime: string;
  title: string;
  meetLink: string;
  meetingType: string;
  organizer: string;
  summary: string;
  feedback: boolean;
}

export interface LocationsState {
  meetings: IMeetings[];
  feedback: IFeedback[];
}

const initialState: LocationsState = {
  meetings: [],
  feedback: [],
};

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    setMeetings(state, action: PayloadAction<LocationsState['meetings']>) {
      state.meetings = action.payload;
    },
    setMeetFeedback(state, action: PayloadAction<LocationsState['feedback']>) {
      state.feedback = action.payload;
    },
  },
});

export const { setMeetings, setMeetFeedback } = meetingsSlice.actions;

export default meetingsSlice.reducer;
