/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CollectionReference,
  DocumentData,
  doc,
  writeBatch,
} from 'firebase/firestore';
import { firestoreDb } from '../firebase';
import { MAX_BATCH_SIZE } from '../firebase/utils';
import { useAppDispatch } from '../store/hooks';
import { setShowBackdrop, setShowSnackbar } from '../store/ui.store';

function replaceSpecialCharacters(str: string) {
  const pattern = /(?:pvt[-\s*]?ltd|private\s*limited|ltd|limited)$/gi;
  return str
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase()
    .replace(pattern, '')
    .trim();
}

const useBatch = () => {
  const dispatch = useAppDispatch();

  const writeBatchData = async (
    data: any,
    collectionRef: CollectionReference<DocumentData>,
    fileNameKey: string
  ) => {
    if (data.length == 0) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: 'There is no data to upload',
        })
      );
      dispatch(setShowBackdrop(false));
      return;
    }
    dispatch(setShowBackdrop(true));
    try {
      // Split jsonData into smaller batches of 500 items each
      const numBatches = Math.ceil(data.length / MAX_BATCH_SIZE);
      const batchSize = 10; // Number of batches to process at a time
      const batchOperations = [];

      for (let i = 0; i < numBatches; i += batchSize) {
        const batchGroup = [];

        for (let j = i; j < i + batchSize && j < numBatches; j++) {
          const batch = writeBatch(firestoreDb);
          const batchData = data.slice(
            j * MAX_BATCH_SIZE,
            (j + 1) * MAX_BATCH_SIZE
          );

          batchData.forEach((e: any) => {
            const path = replaceSpecialCharacters(`${e[fileNameKey]}`);
            if (path && path.length > 0) {
              const docRef = doc(collectionRef, path);
              batch.set(docRef, e);
            }
          });

          batchGroup.push(batch.commit());
        }

        batchOperations.push(Promise.all(batchGroup));
      }
      await Promise.all(batchOperations);
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'success',
          msg: 'Data uploaded successfully',
        })
      );
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: 'Error uploading data',
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { writeBatchData };
};

export default useBatch;
