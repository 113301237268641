import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { QueryDocumentSnapshot, Timestamp } from 'firebase/firestore';
import { IFinancialDoctor } from './doctors.store';

export interface IPost {
  createdAt: Timestamp;
  description: string;
  hashtags: string[];
  images: string[];
  title: string;
  updatedAt: Timestamp;
  url: string;
  userId: string;
  status: string;
  postId: string;
  doctorInfo?: IFinancialDoctor;
  docId: string;
}

type Vote = {
  [key: string]: number;
};

export interface IPolls {
  createdAt: Timestamp;
  images: string[];
  options: string[];
  question: string;
  status: string;
  updatedAt: Timestamp;
  url: string;
  userId: string;
  pollId: string;
  votes?: Vote;
  doctorInfo?: IFinancialDoctor;
  rejectionReasons?: { reason: string; createdAt: Timestamp }[];
  docId: string;
}

export interface IDriveInnUser {
  avatar: string;
  name: string;
  username: string;
  uid: string;
  id?: string;
}

export interface LocationsState {
  post?: IPost;
  postSnap?: QueryDocumentSnapshot;
  users: IDriveInnUser[];
  pendingPosts: IPost[];
  approvedPosts: IPost[];
  rejectedPosts: IPost[];
  pendingPolls: IPolls[];
  approvedPolls: IPolls[];
  rejectedPolls: IPolls[];
}

const initialState: LocationsState = {
  users: [],
  pendingPosts: [],
  approvedPosts: [],
  rejectedPosts: [],
  pendingPolls: [],
  approvedPolls: [],
  rejectedPolls: [],
};

export const driveInnSlice = createSlice({
  name: 'driveInn',
  initialState,
  reducers: {
    setPost(state, action: PayloadAction<LocationsState['postSnap']>) {
      state.postSnap = action.payload;
      state.post = {
        ...action.payload?.data(),
        postId: action.payload?.id,
      } as IPost;
    },
    setPendingPosts(
      state,
      action: PayloadAction<LocationsState['pendingPosts']>
    ) {
      state.pendingPosts = action.payload;
    },
    setApprovedPosts(
      state,
      action: PayloadAction<LocationsState['approvedPosts']>
    ) {
      state.approvedPosts = action.payload;
    },
    setRejectedPosts(
      state,
      action: PayloadAction<LocationsState['rejectedPosts']>
    ) {
      state.rejectedPosts = action.payload;
    },
    setPendingPolls(
      state,
      action: PayloadAction<LocationsState['pendingPolls']>
    ) {
      state.pendingPolls = action.payload;
    },
    setApprovedPolls(
      state,
      action: PayloadAction<LocationsState['approvedPolls']>
    ) {
      state.approvedPolls = action.payload;
    },
    setRejectedPolls(
      state,
      action: PayloadAction<LocationsState['rejectedPolls']>
    ) {
      state.rejectedPolls = action.payload;
    },
    setUser(state, action: PayloadAction<LocationsState['users']>) {
      state.users.push(action.payload[0]);
    },
    setUsers(state, action: PayloadAction<LocationsState['users']>) {
      // state.users.push(action.payload[0]);
      state.users = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPost,
  setPendingPosts,
  setApprovedPosts,
  setRejectedPosts,
  setPendingPolls,
  setApprovedPolls,
  setRejectedPolls,
  setUsers,
  setUser,
} = driveInnSlice.actions;

export default driveInnSlice.reducer;
