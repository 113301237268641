import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { Pagination } from '../utils/types';

export enum BookingStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Completed = 'Completed',
  Timeout = 'Timeout',
  Cancelled = 'Cancelled',
}

export type BookingStatusType =
  | 'Pending'
  | 'Accepted'
  | 'Rejected'
  | 'Completed'
  | 'Timeout'
  | 'Cancelled';

export interface IExperience {
  companyName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  startDate: Date;
  endDate: Date;
  isPresentCompany: boolean;
  designation: string;
  uid?: string;
}

export interface ISlot {
  startTime: string;
  endTime: string;
}

export interface ISlots {
  monday: ISlot[];
  tuesday: ISlot[];
  wednesday: ISlot[];
  thursday: ISlot[];
  friday: ISlot[];
  saturday: ISlot[];
  sunday: [];
  updatedAt: Timestamp;
}

export interface IDocAddress {
  addressline1: string;
  addressline2: string;
  city: string;
  createdAt: Timestamp;
  pincode: string;
  state: string;
  updatedAt: Timestamp;
}

export interface IExperience {
  companyName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  startDate: Date;
  endDate: Date;
  isPresentCompany: boolean;
  designation: string;
  docId?: string;
}

export interface ISlot {
  startTime: string;
  endTime: string;
}

export interface ISlots {
  monday: ISlot[];
  tuesday: ISlot[];
  wednesday: ISlot[];
  thursday: ISlot[];
  friday: ISlot[];
  saturday: ISlot[];
  sunday: [];
  updatedAt: Timestamp;
}

export interface IDocAddress {
  addressline1: string;
  addressline2: string;
  city: string;
  createdAt: Timestamp;
  pincode: string;
  state: string;
  updatedAt: Timestamp;
}

export interface IFinancialDoctor {
  // user: IExperience[];
  uid: string;
  gender: string;
  email: string;
  updateProfessionalInfo: boolean;
  updateTimeslots: boolean;
  mobile: string;
  updateSkillsInfo: boolean;
  altMobile: string;
  bio: string;
  title: string;
  location: string;
  leadName: string;
  avatar: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  verificationStatus: string;
  online: boolean;
  languages: string[];
  // experiences?: IExperience[];
  // slots?: ISlots[];
  // documents?: IDocuments[];
  // address?: IDocAddress[];
}
export interface IRejection {
  reason?: string | undefined;
  createdAt?: Timestamp | undefined;
}

export interface IPrescribe {
  requirements: string[];
  suggestions: string[];
}

export interface IDocuments {
  title: string;
  summary: string;
  type: string;
  url: string;
  uploadedBy: string;
  createdAt: string;
  name?: string;
}

export interface IChats {
  content: string;
  createdAt: string;
  from: string;
  messageType: string;
}

export interface IBooking extends Pagination {
  docId: string;
  bookingId: string;
  client: string;
  doctor: string;
  info: Info;
  issue: string;
  orderId: string;
  paymentStatus: string;
  rejectedBy: string[];
  status: BookingStatusType;
  timeSlot: Date;
  timeouts: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
  rejections?: IRejection[];
  cancelled?: string;
  prescribe?: IPrescribe[];
  documents?: IDocuments[];
  chats?: IChats[];
}

export interface Info {
  amount: number;
  clientInfo: ClientInfo;
  doctorInfo: DoctorInfo;
  description: string;
  duration: string;
  meetLink: string;
}

export interface ClientInfo {
  name: string;
  email: string;
  avatar: string;
  contactNo: string;
  profession: string;
  selectedLanguages: string[];
  selectedBank: string;
}

export interface DoctorInfo {
  name: string;
  avatar: string;
}

export interface IEarnings {
  bookingId: string;
  uid: string;
  createdAt: Timestamp;
  earned: number;
  userId: string;
  duration?: string;
}

interface SkillInfo {
  duration: string;
  price: number;
  banks: string[];
  skill: string;
}

export interface ISkill {
  skills: string[];
  skillsInfo: {
    [key: string]: SkillInfo;
  };
  uid: string;
}

export interface DoctorState {
  approvedDoctors: IFinancialDoctor[];
  pendingDoctors: IFinancialDoctor[];
  bookings: IBooking[];
  earnings: IEarnings[];
  skills: ISkill[];
}

const initialState: DoctorState = {
  bookings: [],
  earnings: [],
  skills: [],
  approvedDoctors: [],
  pendingDoctors: [],
};

export const doctorSlice = createSlice({
  name: 'financial-doctor',
  initialState,
  reducers: {
    setApprovedDoctors(
      state,
      action: PayloadAction<DoctorState['approvedDoctors']>
    ) {
      state.approvedDoctors = action.payload;
    },
    setPendingDoctors(
      state,
      action: PayloadAction<DoctorState['pendingDoctors']>
    ) {
      state.pendingDoctors = action.payload;
    },
    setBookings(state, action: PayloadAction<DoctorState['bookings']>) {
      const data = action.payload;
      function filterUniqueById() {
        const uniqueIds = new Set();
        return data.filter((item) => {
          if (!uniqueIds.has(item.bookingId)) {
            uniqueIds.add(item.bookingId);
            return true;
          }
          return false;
        });
      }
      state.bookings = filterUniqueById();
    },
    setEarnings(state, action: PayloadAction<DoctorState['earnings']>) {
      state.earnings = action.payload;
    },
    setSkills(state, action: PayloadAction<DoctorState['skills']>) {
      state.skills = action.payload;
    },
  },
});

export const {
  setBookings,
  setEarnings,
  setSkills,
  setApprovedDoctors,
  setPendingDoctors,
} = doctorSlice.actions;

export default doctorSlice.reducer;
