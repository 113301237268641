import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Report {
  task: string;
  quantity: number;
}

export interface DprEntry {
  data: {
    time: string;
    reports: Report[];
    title: string;
    createdAt: Date;
    id: number;
  }[];
  date: string;
  createdAt: Date;
}

export interface DprState {
  dpr: Record<string, DprEntry[]>;
}

const initialState: DprState = {
  dpr: {},
};

export const dprSlice = createSlice({
  name: 'dpr',
  initialState,
  reducers: {
    setDpr: (
      state,
      action: PayloadAction<{ uid: string; dpr: DprEntry[] }>
    ) => {
      const { uid, dpr } = action.payload;
      state.dpr[uid] = dpr;
    },
  },
});

export const { setDpr } = dprSlice.actions;

export default dprSlice.reducer;
