import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IProspect {
  docId: string;
  campaignId: string;
  companyName: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  dealAmount: number;
  empId: string;
  leadName: string;
  location: string;
  mobile: string;
  ownHouse: boolean;
  pincode: string;
  salary: number;
  serviceType: string;
  source?: string;
  sourceType: string;
  priority?: string;
  userId: string | null;
  remarks: string;
}

export interface LocationsState {
  prospects: IProspect[];
}

const initialState: LocationsState = {
  prospects: [],
};

export const prospectSlice = createSlice({
  name: 'prospects',
  initialState,
  reducers: {
    setProspects(state, action: PayloadAction<LocationsState['prospects']>) {
      state.prospects = action.payload;
    },
  },
});

export const { setProspects } = prospectSlice.actions;

export default prospectSlice.reducer;
