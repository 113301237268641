/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CollectionReference,
  DocumentData,
  QueryDocumentSnapshot,
  getCountFromServer,
  query,
} from 'firebase/firestore';

export const PaginationUtil = async (
  col: CollectionReference<DocumentData>,
  conditions: any[],
  docs: QueryDocumentSnapshot<DocumentData>[]
) => {
  // Create a query without limit or startAfter
  const q = query(
    col,
    ...conditions.filter((c) => c.type !== 'limit' && c.type !== 'startAfter')
  );

  // Fetch the total count using the modified query
  const countSnapshot = await getCountFromServer(q);
  const total = countSnapshot.data().count;

  // Utilize the original query's results for pagination data
  const lastDoc = docs[docs.length - 1];
  const hasMore = docs.length >= 10;

  return { total, lastDoc, hasMore };
};
