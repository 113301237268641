import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface AddEmployee {
  personalDetails: PersonalDetails;
  address: IAddress;
  bankDetails: IBankDetails;
  department: string;
  subDepartments: string[];
  role: string;
  level: string;
  doj: Timestamp;
  ctc: string;
  workType: string;
  reportingTo: string;
  paymentType: string;
  empId: string;
  uid: string;
  active: boolean;
  officialMail: string;
  entities?: Record<string, string[]>;
}

export interface PersonalDetails {
  avatar: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: Date;
  personalMail: string;
  mobile: string;
  secondaryMobile: string;
  isPasswordUpdated: boolean;
}

export interface IBankDetails {
  bankName: string;
  accountNumber: string;
  ifscCode: string;
}

export interface IAddress {
  addressLine1: string;
  addressLine2: string;
  landmark: string;
  city: string;
  state: string;
  pincode: string;
  lat?: number;
  lng?: number;
}

export interface IDepartment {
  sub: ISubDepartments[];
  name: string;
  id: string;
}

export interface ISubDepartments {
  id: string;
  name: string;
}

interface UserState {
  employees: AddEmployee[];
  employeesMin: IHierarchy[];
}

export interface IHierarchy {
  avatar: string;
  department: string;
  empId: string;
  name: string;
  reportingTo: string;
  role: string;
  uid: string;
}

const initialState: UserState = {
  employees: [],
  employeesMin: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmployees(state, action: PayloadAction<UserState['employees']>) {
      state.employees = action.payload;
    },
    setEmployeesMin(state, action: PayloadAction<UserState['employeesMin']>) {
      state.employeesMin = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmployees, setEmployeesMin } = userSlice.actions;

export default userSlice.reducer;
