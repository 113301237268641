import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { useCallback } from 'react';
import { firestoreDb } from '../firebase';
import { firebaseError, FIRESTORE_PATH } from '../firebase/utils';
import {
  IConfiguration,
  setBusinessTypes,
  setChecklistDocs,
  setDialerFeedbackOptions,
  setEntityTypes,
  setLeadStatus,
  setLeaveTypes,
  setNatureOfBusiness,
  setRoles,
  setServiceTypes,
  setSource,
  setSurrogate,
  setWorkTypes,
} from '../store/configuration.store';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setShowBackdrop, setShowSnackbar } from '../store/ui.store';

const CONFIGURATION_LIST = [
  'serviceTypes',
  'workTypes',
  'entityTypes',
  'checklistDocs',
  'source',
  'leadStatus',
  'dialerFeedbackOptions',
  'leaveTypes',
  'roles',
  'natureOfBusiness',
  'businessTypes',
  'surrogate',
] as const;

export type Configuration = (typeof CONFIGURATION_LIST)[number];

const CONFIGURATION_PATH: Record<Configuration, string> = {
  serviceTypes: FIRESTORE_PATH.serviceTypes,
  workTypes: FIRESTORE_PATH.workTypes,
  entityTypes: FIRESTORE_PATH.entityTypes,
  checklistDocs: FIRESTORE_PATH.checklistDocuments,
  source: FIRESTORE_PATH.source,
  leadStatus: FIRESTORE_PATH.leadStatus,
  dialerFeedbackOptions: FIRESTORE_PATH.dialerFeedbackOptions,
  leaveTypes: FIRESTORE_PATH.leaveTypes,
  roles: FIRESTORE_PATH.roles,
  natureOfBusiness: FIRESTORE_PATH.natureOfBusiness,
  businessTypes: FIRESTORE_PATH.businessType,
  surrogate: FIRESTORE_PATH.businessType,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SET_CONFIGURATION_MAPPER: Record<Configuration, any> = {
  serviceTypes: setServiceTypes,
  workTypes: setWorkTypes,
  entityTypes: setEntityTypes,
  checklistDocs: setChecklistDocs,
  source: setSource,
  leadStatus: setLeadStatus,
  dialerFeedbackOptions: setDialerFeedbackOptions,
  leaveTypes: setLeaveTypes,
  roles: setRoles,
  natureOfBusiness: setNatureOfBusiness,
  businessTypes: setBusinessTypes,
  surrogate: setSurrogate,
} as const;

const useConfigurations = (type: Configuration) => {
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector(
    (state) => state.configuration[type]
  );

  const getAllConfiguration = useCallback(async () => {
    CONFIGURATION_LIST.forEach(async (item) => {
      await getDocs(
        collection(firestoreDb, CONFIGURATION_PATH[item as Configuration])
      ).then((querySnapshot) => {
        const list: IConfiguration[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as IConfiguration;
          list.push({
            id: data.id,
            name: data.name,
            status: data?.status,
            code: data?.code,
            ...(data?.surrogates && { surrogates: data?.surrogates }),
          });
        });
        dispatch(SET_CONFIGURATION_MAPPER[item as Configuration](list));
      });
    });
  }, [dispatch]);

  const getData = useCallback(async () => {
    await getDocs(collection(firestoreDb, CONFIGURATION_PATH[type])).then(
      (querySnapshot) => {
        const list: IConfiguration[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as IConfiguration;
          list.push({ id: data.id, name: data.name });
        });
        dispatch(SET_CONFIGURATION_MAPPER[type](list));
      }
    );
  }, [dispatch, type]);

  const addData = async (name: string) => {
    const docId = Math.floor(new Date().getTime() / 1000);
    await setDoc(doc(firestoreDb, `${CONFIGURATION_PATH[type]}/${docId}`), {
      id: docId,
      name,
      status: true,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    }).then(() => {
      getData();
    });
  };

  const updateData = async (
    id: string,
    configuration: Partial<IConfiguration>
  ) => {
    dispatch(setShowBackdrop(true));
    try {
      await updateDoc(doc(firestoreDb, `${CONFIGURATION_PATH[type]}/${id}`), {
        ...configuration,
        updatedAt: serverTimestamp(),
      });

      const updatedData = configurationData.map((c) =>
        c.id === id ? { ...c, ...configuration } : c
      );
      dispatch(SET_CONFIGURATION_MAPPER[type](updatedData));
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'success',
          msg: 'Item Updated Successfully!',
        })
      );
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: `${firebaseError(error)}`,
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const deleteData = async (id: number) => {
    await deleteDoc(doc(firestoreDb, `${CONFIGURATION_PATH[type]}/${id}`)).then(
      () => {
        getData();
      }
    );
  };

  return { getAllConfiguration, getData, addData, deleteData, updateData };
};

export default useConfigurations;
