import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAppDispatch } from '../../store/hooks';
import { setDeleteDialog } from '../../store/ui.store';

interface IDialogProps {
  open: boolean;
  onDelete?: () => void;
}

const DeleteConfirmationDialog = ({ open, onDelete }: IDialogProps) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setDeleteDialog({ show: false }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Do you want to delete?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete Description
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
