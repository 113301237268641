import { Button, MenuItem, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import InputField from '../../components/InputField';
import TableWrapper from '../../components/TableWrapper';
import useEntities from '../../hooks/useEntities';
import useReadExcelFile from '../../hooks/useReadExcelFile';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setShowBackdrop, setShowSnackbar } from '../../store/ui.store';
import { firebaseError } from '../../firebase/utils';

const headCells = [
  { id: 'testId', label: 'Test Case Id' },
  { id: 'emi', label: 'Proposed EMI' },
  { id: 'loanAmount', label: 'Loan Amount' },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'internalCategory',
    label: 'Internal Category',
  },
  {
    id: 'startSalary',
    label: 'Salary Start',
  },
  {
    id: 'endSalary',
    label: 'Salary End',
  },
  {
    id: 'raac',
    label: 'RAAC',
  },
  {
    id: 'foir',
    label: 'FOIR',
  },
  {
    id: 'tenure',
    label: 'Tenure',
  },
  {
    id: 'processingFee',
    label: 'Processing Fee',
  },
  {
    id: 'minLoanAmount',
    label: 'Min Loan Amount',
  },
  {
    id: 'maxLoanAmount',
    label: 'Max Loan Amount',
  },
  {
    id: 'specialRaac',
    label: 'Special RAAC',
  },
  {
    id: 'specialPF',
    label: 'Special PF',
  },
];

export interface Eligibility {
  eligibilityRate?: EligibilityRate;
  proposedEmi?: number;
  loanAmount?: number;
  entity?: Entity;
  success?: boolean;
  message?: string;
}

export interface EligibilityRate {
  salaryStart: number;
  foir: number;
  salaryEnd: number;
  raac: number;
  specialRaac: number;
  internalCategory: string;
  processingFees: number;
  minLoanAmount: number;
  maxLoanAmount: number;
  category: string;
  tenure: number;
  specialProcessingFees: number;
}

export interface Entity {
  logo: string;
  name: string;
  nickname: string;
  id: number;
}

const PLEligibilityTest = () => {
  const dispatch = useAppDispatch();
  const { getEntities } = useEntities();
  const entities = useAppSelector((state) =>
    state.entity.entities.filter((e) =>
      e.allowEligibilityChecks?.includes('PL')
    )
  );
  const surrogates = useAppSelector(
    (state) =>
      state.configuration.serviceTypes.find((s) => s.id === 'PL')?.surrogates
  );
  const [entity, setEntity] = useState<number | undefined>();
  const [selectedSurrogate, setSelectedSurrogate] = useState('Primary');
  const [file, setFile] = useState<File | undefined>();
  const [testIds, setTestIds] = useState<string[]>([]);
  const [data, setData] = useState();

  const readExcelFile = useReadExcelFile();

  const checkEligibility = async () => {
    if (entity && selectedSurrogate && file) {
      dispatch(setShowBackdrop(true));
      const jsonData = (await readExcelFile(file, false)) as [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setTestIds(jsonData?.map((e: any) => e.testCaseId));
      try {
        await fetch(
          `https://checkpleligibilitytest-${process.env.REACT_APP_CLOUD_FUNCTION}-uc.a.run.app`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              entityId: entity,
              surrogate: selectedSurrogate,
              data: jsonData,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setData(data);
          });
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: `${firebaseError(error)}`,
            type: 'error',
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    }
  };

  useEffect(() => {
    getEntities();
  }, [getEntities]);

  return (
    <div>
      <div className="grid grid-cols-4 gap-5">
        <InputField
          id="entity"
          label="Entity"
          select
          required
          defaultValue=""
          onChange={(e) => setEntity(Number(e.target.value))}
        >
          {entities.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </InputField>
        {surrogates && surrogates.length > 0 && (
          <InputField
            id="surrogate"
            label="Surrogate"
            select
            required
            disabled={true}
            defaultValue={selectedSurrogate ?? ''}
            value={selectedSurrogate ?? ''}
            onChange={(e) => {
              setSelectedSurrogate(e.target.value);
            }}
          >
            {surrogates.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </InputField>
        )}
        <InputField
          id="file"
          label="Test Cases File"
          InputLabelProps={{
            shrink: true,
          }}
          type="file"
          required
          onChange={(event) => {
            const target = event.target as HTMLInputElement;
            const file: File = (target.files as FileList)[0];
            setFile(file);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            checkEligibility();
          }}
        >
          CHECK
        </Button>
      </div>
      {data && (
        <TableWrapper title="" headCells={headCells} data={entities}>
          {testIds.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            const hasEligibility = data[row]['eligibilityRate'] != undefined;
            const eligibilityRate = data[row][
              'eligibilityRate'
            ] as EligibilityRate;
            return (
              <TableRow hover tabIndex={-1} key={row}>
                <TableCell component="th" id={labelId} scope="row">
                  {row}
                </TableCell>
                <TableCell>
                  {!hasEligibility
                    ? data[row]['message']
                    : Number(data[row]['proposedEmi']).toFixed()}
                </TableCell>
                <TableCell>
                  {!hasEligibility
                    ? ''
                    : Number(data[row]['loanAmount']).toFixed()}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.category}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.internalCategory}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.salaryStart}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.salaryEnd}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.raac}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.foir}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.tenure}
                </TableCell>
                <TableCell>
                  {!hasEligibility
                    ? ''
                    : Number(eligibilityRate.processingFees).toFixed()}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.minLoanAmount}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.maxLoanAmount}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.specialRaac}
                </TableCell>
                <TableCell>
                  {!hasEligibility ? '' : eligibilityRate.specialProcessingFees}
                </TableCell>
              </TableRow>
            );
          })}
        </TableWrapper>
      )}
    </div>
  );
};

export default PLEligibilityTest;
