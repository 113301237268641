import React from 'react';
import InputField from '../../../components/InputField';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { InvoiceType, setInvoiceData } from '../../../store/invoice.store';
import { useAppDispatch } from '../../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import useInvoice from '../../../hooks/useInvoice.store';

const CreateInvoice = () => {
  const { id } = useParams();
  const { addInvoice } = useInvoice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InvoiceType>({
    defaultValues: {
      items: [
        {
          description: '',
          doj: '',
          serviceAmount: '',
          payableAmount: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const onSubmit = handleSubmit(async (data: InvoiceType) => {
    dispatch(setInvoiceData({ ...data }));
    await addInvoice({ id, ...data });
    navigate(`/ops/loans-summary/${id}/invoice/pdf-page`);
  });

  return (
    <form noValidate onSubmit={onSubmit}>
      <Box className="grid grid-cols-2 gap-2">
        <InputField
          label="Name"
          {...register('name', { required: true })}
          error={!!errors.name}
        />
        <InputField
          type="date"
          label="Invoice Date"
          {...register('date', { required: true })}
          InputLabelProps={{ shrink: true }}
          error={!!errors.date}
        />
        <InputField
          label="Invoice No"
          {...register('invoiceNo', { required: true })}
          error={!!errors.invoiceNo}
        />
        <InputField label="GST No" {...register('GSTNo')} />
        <InputField
          label="PAN No"
          {...register('PAN', { required: true })}
          error={!!errors.PAN}
        />
      </Box>

      {fields.map((item, index) => (
        <div key={item.id}>
          <Typography variant="h6" color={'#3B5998'}>
            Item {index + 1}
          </Typography>
          <div className="flex items-center ">
            <div className="grid grid-cols-2 w-full gap-2">
              <InputField
                label={`Description ${index + 1}`}
                {...register(`items.${index}.description`, { required: true })}
                error={!!errors?.items?.[index]?.description}
              />
              <InputField
                type="date"
                label={`DOJ ${index + 1}`}
                {...register(`items.${index}.doj`, { required: true })}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.items?.[index]?.doj}
              />
              <InputField
                type="number"
                label={`Service Amount ${index + 1}`}
                {...register(`items.${index}.serviceAmount`, {
                  required: true,
                })}
                error={!!errors?.items?.[index]?.serviceAmount}
              />
              <InputField
                type="number"
                label={`Payable Amount ${index + 1}`}
                {...register(`items.${index}.payableAmount`, {
                  required: true,
                })}
                error={!!errors?.items?.[index]?.payableAmount}
              />
            </div>
            {index !== 0 && (
              <RemoveCircle
                sx={{ color: 'red', cursor: 'pointer' }}
                onClick={() => {
                  remove(index);
                }}
              />
            )}
          </div>
        </div>
      ))}

      {/* Button to add more items */}
      <Button
        variant="outlined"
        size="small"
        className="mt-2"
        onClick={() =>
          append({
            description: '',
            doj: '',
            serviceAmount: '',
            payableAmount: '',
          })
        }
      >
        Add Item
      </Button>

      <Grid container justifyContent="center" marginY={2} gap={2}>
        <Button type="submit" variant="contained" className="capitalize px-8">
          Create
        </Button>
      </Grid>
    </form>
  );
};

export default CreateInvoice;
