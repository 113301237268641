import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { IDocuments } from '../../store/doctors.store';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    borderRadius: '1rem',
    border: '1px solid #C4C4C4',
    minWidth: '60%',
  },
}));

const DocPreview = ({
  open,
  onClose,
  docData,
}: {
  open: boolean;
  docData?: IDocuments | string | undefined;
  onClose: () => void;
}) => {
  if (typeof docData === 'string') {
    return (
      <BootstrapDialog open={open} onClose={onClose}>
        <DialogContent>{docData}</DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </BootstrapDialog>
    );
  }
  return (
    <BootstrapDialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography color={'#3B5998'}>
          {docData !== undefined && docData.title}
        </Typography>
        <Box>
          <img
            src={docData?.url}
            width={'100%'}
            height={'100%'}
            style={{ objectFit: 'contain' }}
            alt={docData?.title}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DocPreview;
