import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { Pagination } from '../utils/types';

export interface IApplication extends Pagination {
  id: string;
  leadName: string;
  leadId: string;
  serviceType: string;
  dealAmount: number;
  leadStatus: string;
  occupation: string;
  pincode: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  address: string;
  allowEligibilityCheck: boolean;
  createdBy: string;
  personal_loan?: {
    cibilIssues: boolean;
    companyName: string;
    obligations: number;
    providentFund: boolean;
    salary: number;
  };
  business_loan?: {
    businessName: string;
    businessRegDate: Timestamp;
    businessType: string;
    isBusinessRegistered: boolean;
    isGSTFilled: boolean;
    isITRFilled: boolean;
    natureOfBusiness: string;
    natureOfBusinessOther: string;
    ownHouse: boolean;
    ownHousePincode?: string;
    gstTurnover?: number;
    turnover?: number;
    netProfit?: number;
  };
  property_loan?: {
    area: number;
    balanceTransfer: boolean;
    coApplicantSupport: boolean;
    isPropertyRegistered: boolean;
    linkedDocYears: number;
    loanType: string;
    propertyCost: number;
    propertyFinalized: boolean;
    propertyType: string;
  };
}

export interface IEligibility {
  createdAt: Timestamp;
  eligibility?: {
    eligibilityRate: {
      category: string;
      foir: number;
      internalCategory: string;
      maxLoanAmount: number;
      minLoanAmount: number;
      processingFees: number;
      raac: number;
      salaryEnd: number;
      salaryStart: number;
      specialProcessingFees?: number | null;
      specialRaac?: number | null;
      tenure: number;
    };
    entity: {
      id: number;
      logo: string;
      name: string;
      nickname: string;
    };
    loanAmount: number;
    proposedEmi: number;
  }[];
  selected?: {
    entities?: number[];
    surrogates?: Record<string, number[]>;
    status?: Record<number, string>;
  };
  surrogates?: Record<string, number[]>;
  localEligibility?: number[];
  localEligibilityDetails?: Record<string, ILocalEligibility>;
}

export interface IRemarks {
  content: string;
  from: string;
  createdAt: string;
  messageType: string;
  to?: string | null;
  entityId?: number | null;
  entityName?: string | null;
}

export interface IEntityApplication {
  docId?: string;
  createdAt: Timestamp;
  applicationId: string;
  leadId: string;
  entity: Entity;
  updatedAt: Timestamp;
  priority: string;
  parentApplication: string;
  status: string;
  isPending: null;
  staff: Staff;
  sanctionLetter: null;
  leadName: string;
  opsManager: string;
  eligibility: Eligibility;
  serviceType: string;
  opsExecutive: string;
  loginTime: Timestamp;
}

export interface Eligibility {
  loanAmount: number;
  emi: number;
  roi: number;
  dealAmount: number;
  processingFees: number;
  tenure: number;
}

export interface Entity {
  nickname: string;
  id: number;
}

export interface Staff {
  name: string;
  mobile: string;
  id: number;
}

export interface PortfolioState {
  applications: IApplication[];
  entityApplications: IEntityApplication[];
  eligibility?: IEligibility;
  remarks: IRemarks[];
}

export interface ILocalEligibility {
  entityId: number;
  loanAmount: number;
  tenure: number;
  raac: number;
  proposedEmi: number;
  processingFees: number;
}

const initialState: PortfolioState = {
  applications: [],
  entityApplications: [],
  remarks: [],
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    resetPortfolio: () => initialState,
    setApplications(
      state,
      action: PayloadAction<PortfolioState['applications']>
    ) {
      const data = [...state.applications, ...action.payload];
      function filterUniqueById() {
        const uniqueIds = new Set();
        return data.filter((item) => {
          if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            return true;
          }
          return false;
        });
      }
      state.applications = filterUniqueById();
    },
    setRemarks(state, action: PayloadAction<PortfolioState['remarks']>) {
      state.remarks = action.payload;
    },
    setEntityApplications(
      state,
      action: PayloadAction<PortfolioState['entityApplications']>
    ) {
      state.entityApplications = action.payload;
    },
    setEligibility(
      state,
      action: PayloadAction<PortfolioState['eligibility']>
    ) {
      state.eligibility = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetPortfolio,
  setApplications,
  setRemarks,
  setEntityApplications,
  setEligibility,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
