import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestoreDb } from '../firebase';
import { FIRESTORE_PATH, firebaseError } from '../firebase/utils';
import { AddEntity, IEntity, setEntities } from '../store/entity.store';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  setDeleteDialog,
  setShowBackdrop,
  setShowSnackbar,
} from '../store/ui.store';
import useFileUpload from './useFileUpload';

const useEntities = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uploadFile = useFileUpload();

  const entities = useAppSelector((state) => state.entity.entities);

  const getEntities = useCallback(async () => {
    if (entities.length !== 0) return;
    try {
      dispatch(setShowBackdrop(true));
      const q = query(
        collection(firestoreDb, FIRESTORE_PATH.entities),
        orderBy('createdAt', 'asc')
      );
      const querySnapshot = await getDocs(q);
      const _entities = querySnapshot.docs.map((doc) => doc.data() as IEntity);
      dispatch(
        setEntities(
          _entities.sort((a, b) => a.nickname.localeCompare(b.nickname))
        )
      );
    } catch (error) {
      // dispatch(setShowBackdrop(false));
    } finally {
      dispatch(setShowBackdrop(false));
    }
  }, [dispatch, entities.length]);

  const getEntity = useCallback(
    async (uid: string) => {
      if (entities.length === 0) {
        const docSnap = await getDoc(
          doc(firestoreDb, `${FIRESTORE_PATH.entities}/${uid}`)
        );
        dispatch(setEntities([docSnap.data() as IEntity]));
        return docSnap.data() as IEntity;
      } else {
        return entities.find((e) => `${e.id}` === uid);
      }
    },
    [dispatch, entities]
  );

  const updateLogo = async (entityId: number, file: File) => {
    dispatch(setShowBackdrop(true));
    try {
      const fileExtension = file.name.split('.').pop();
      const fileUrl = await uploadFile(
        file,
        `entity/${entityId}/logo.${fileExtension}`
      );
      await updateDoc(
        doc(firestoreDb, `${FIRESTORE_PATH.entities}/${entityId}`),
        {
          logo: fileUrl.url,
          updatedAt: serverTimestamp(),
        }
      );
      const updatedEntities = entities.map((c) =>
        c.id === entityId ? { ...c, logo: fileUrl.url } : c
      );
      dispatch(setEntities(updatedEntities));
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'success',
          msg: 'Logo Updated Successfully!',
        })
      );
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: `${firebaseError(error)}`,
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const addEntity = async (entity: AddEntity, file?: File) => {
    dispatch(setShowBackdrop(true));
    const docId = Math.floor(new Date().getTime() / 1000);
    try {
      let fileUrl = null;
      if (file) {
        const fileExtension = file.name.split('.').pop();
        fileUrl = await uploadFile(
          file,
          `entity/${docId}/logo.${fileExtension}`
        );
      }
      if (file && fileUrl) {
        entity.logo = fileUrl.url;
      }
      await setDoc(doc(firestoreDb, `${FIRESTORE_PATH.entities}/${docId}`), {
        ...entity,
        id: docId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'success',
          msg: 'Entity Added Successfully!',
        })
      );
      navigate('/entities');
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: `${firebaseError(error)}`,
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  interface IUpdateEntity {
    id: number;
    entity: Partial<IEntity>;
    serviceType?: string;
    surrogate?: string;
    updateType?: string;
    status?: boolean;
    isLAP?: boolean;
  }

  const updateEntity = async ({
    id,
    entity,
    serviceType,
    surrogate,
    updateType,
    status,
    isLAP = false,
  }: IUpdateEntity) => {
    dispatch(setShowBackdrop(true));
    try {
      const updates = { ...entity, updatedAt: serverTimestamp() };
      await updateDoc(
        doc(firestoreDb, `${FIRESTORE_PATH.entities}/${id}`),
        updates
      );

      if (updateType === 'enabledSurrogates' && isLAP) {
        const occupations = ['Rental Income', 'Salaried', 'Self Employed'];
        const updatePromises = occupations.map((occupation) =>
          updateDoc(
            doc(
              firestoreDb,
              `entities/${id}/status/${serviceType}/${surrogate}/${occupation}`
            ),
            { status, updatedAt: serverTimestamp() }
          )
        );
        await Promise.all(updatePromises);
      } else if (updateType === 'enabledSurrogates') {
        await updateDoc(
          doc(
            firestoreDb,
            `entities/${id}/status/${serviceType}/${surrogate}/${surrogate}`
          ),
          { status, updatedAt: serverTimestamp() }
        );
      }

      const updatedEntities = entities.map((c) =>
        c.id === id ? { ...c, ...entity } : c
      );
      dispatch(setEntities(updatedEntities));
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'success',
          msg: 'Entity Updated Successfully!',
        })
      );
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: `${firebaseError(error)}`,
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateOpsEntity = async (
    opsSelected: Record<string, string[]>,
    role: string,
    removedData?: Record<string, string[]>
  ) => {
    dispatch(setShowBackdrop(true));

    try {
      const updatePromises: Promise<void>[] = [];

      Object.entries(opsSelected).forEach(([entity, serviceTypes]) => {
        serviceTypes.forEach((st) => {
          const docRef = doc(
            firestoreDb,
            `${FIRESTORE_PATH.entities}/${entity}`
          );
          const updateData = {
            [`opsSelected.${st}.${role}`]: true,
            updatedAt: serverTimestamp(),
          };
          updatePromises.push(updateDoc(docRef, updateData));
        });
      });
      if (removedData) {
        Object.entries(removedData).forEach(([entity, serviceTypes]) => {
          serviceTypes.forEach((st) => {
            const docRef = doc(
              firestoreDb,
              `${FIRESTORE_PATH.entities}/${entity}`
            );
            const updateData = {
              [`opsSelected.${st}.${role}`]: false,
            };
            updatePromises.push(updateDoc(docRef, updateData));
          });
        });
      }

      await Promise.all(updatePromises);
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          type: 'error',
          msg: `${firebaseError(error)}`,
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const deleteEntity = async (id: number) => {
    await deleteDoc(doc(firestoreDb, `${FIRESTORE_PATH.entities}/${id}`))
      .then(() => {
        getEntities();
      })
      .finally(() => {
        dispatch(setDeleteDialog({ show: false }));
      });
  };

  return {
    getEntities,
    getEntity,
    addEntity,
    updateEntity,
    deleteEntity,
    updateLogo,
    updateOpsEntity,
  };
};

export default useEntities;
