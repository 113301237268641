import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import InputField from '../../../components/InputField';
import { useForm } from 'react-hook-form';
import useFinancialDoctor from '../../../hooks/useFinancialDoctor';
import { formatMobile } from '../../../utils/utils';

interface RejectionDialogProps {
  uid: string;
  mobile: string;
  onClose: () => void;
}

const RejectionDialog = ({ uid, mobile, onClose }: RejectionDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    reason: string;
  }>();

  const { updateDoctorStatus } = useFinancialDoctor();

  const onSubmit = handleSubmit(async (data) => {
    await updateDoctorStatus(
      formatMobile(mobile),
      uid,
      'Rejected',
      data.reason
    );
    onClose();
  });

  return !uid ? null : (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={Boolean(uid)}
      onClose={onClose}
    >
      <DialogTitle>Reject Doctor</DialogTitle>
      <form onSubmit={onSubmit} noValidate>
        <DialogContent
          dividers
          sx={{
            '&.MuiDialogContent-root': {
              paddingTop: '24px',
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: '24px',
            },
          }}
        >
          <InputField
            id="reason"
            label="Reason"
            {...register('reason', {
              required: true,
            })}
            required
            error={!!errors.reason}
            placeholder="Enter Reason"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RejectionDialog;
