import { Box, styled } from '@mui/material';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import Loader from '../components/Loader';
import NavBar from '../components/NavBar';
import { useAppSelector } from '../store/hooks';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const MainPage = () => {
  const deleteDialog = useAppSelector((state) => state.ui.deleteDialog);

  return (
    <>
      <NavBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <DrawerHeader />
          {/* <FilterDrawer /> */}
          <DeleteConfirmationDialog
            open={deleteDialog.show}
            onDelete={deleteDialog.onDelete}
          />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Box>
      </NavBar>
    </>
  );
};

export default MainPage;
