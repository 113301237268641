import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputField from '../../components/InputField';

interface CardDetail {
  name: string;
  mobileNumber: string;
  emailId: string;
  designation: string;
}
const MarketingPoster = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<CardDetail>();

  const onSubmit = handleSubmit(async () => {
    //  TODO: Implement
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="font-bold bg-white italic hover:italic pt-5 text-2xl underline hover:underline-offset-4">
        Add MarketingPoster Details:
      </div>

      <div className="flex justify-end"></div>

      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-3">
        <InputField
          id="name"
          label="Name"
          {...register('name', {
            required: true,
          })}
          required
          error={!!errors.name}
          placeholder="Enter name"
        />

        <InputField
          id="mobileNumber"
          label="Mobile Number"
          {...register('mobileNumber', {
            required: true,
          })}
          required
          error={!!errors.mobileNumber}
          placeholder="Enter mobileNumber"
        />
        <InputField
          id="emailId"
          label="Email Id"
          {...register('emailId', {
            required: true,
          })}
          required
          error={!!errors.emailId}
          placeholder="Enter emailId"
        />
        <InputField
          id="designation"
          label="Designationr"
          {...register('designation', {
            required: true,
          })}
          required
          error={!!errors.designation}
          placeholder="Enter designation"
        />
      </div>

      <div className="flex justify-center mt-10">
        <Button type="submit" variant="contained" className="w-40">
          SAVE
        </Button>
      </div>
    </form>
  );
};

export default MarketingPoster;
