import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import { ChangeEvent, ReactNode, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setTablePagination } from '../../store/ui.store';
import { Pagination } from '../../utils/types';

export interface HeadCell {
  id: string;
  label: string;
}

interface TableWrapperProps extends Pagination {
  headCells: HeadCell[];
  children: ReactNode;
  toolbarChildren?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  title: string;
  tableContainerSX?: SxProps<Theme>;
  hideToolbar?: boolean;
  onPageChange?: () => void;
}

const TableWrapper = ({
  headCells,
  children,
  toolbarChildren,
  data,
  title,
  tableContainerSX = {
    height: 'calc(100vh - 220px)',
  },
  hideToolbar = false,
  onPageChange,
  total,
}: // startAt,
// hasMore,
TableWrapperProps) => {
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLTableElement | null>(null);
  const { page, rowsPerPage } = useAppSelector(
    (state) => state.ui.tablePagination
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    scrollToTop();
    if (onPageChange && page < newPage) onPageChange();
    dispatch(setTablePagination({ page: newPage, rowsPerPage }));
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    scrollToTop();
    dispatch(
      setTablePagination({
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      })
    );
  };

  useEffect(() => {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      bodyElement.style.overflow = 'hidden'; // Disable page scrolling
    }

    return () => {
      if (bodyElement) {
        bodyElement.style.overflow = ''; // Re-enable page scrolling when the component is unmounted
      }
    };
  }, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {!hideToolbar && (
        <Toolbar>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            className="font-bold"
          >
            {title}
          </Typography>
          {toolbarChildren}
        </Toolbar>
      )}
      <TableContainer sx={tableContainerSX}>
        <Table
          ref={tableRef}
          sx={{ minWidth: 750 }}
          aria-labelledby={title}
          size="medium"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  className="font-bold text-gray-800"
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {children}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total || data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableWrapper;
