import { Font, Text, StyleSheet, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

// Register font
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: require('../../fonts/Roboto-Regular.ttf'),
    },
    {
      src: require('../../fonts/Roboto-Bold.ttf'),
      fontWeight: 'bold',
    },
    {
      src: require('../../fonts/Roboto-Italic.ttf'),
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: require('../../fonts/Roboto-BoldItalic.ttf'),
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

// Sample data for the table
export const tableData = [
  { ID: 1, Name: 'John Doe', Age: 30, City: 'New York' },
  { ID: 2, Name: 'Jane Smith', Age: 25, City: 'Los Angeles' },
  { ID: 3, Name: 'Bob Johnson', Age: 35, City: 'Chicago' },
];

// Styles for the PDF document
export const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: 'auto',
    margin: 'auto',
    marginBottom: 10,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
    border: '1px solid #000',
    padding: 8,
  },
  tableHeader: {
    width: '50%',
    fontWeight: 'bold',
    border: '1px solid #000',
    padding: 8,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  listItemText: {
    paddingLeft: 5,
  },
});

interface TextProps {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}

export const BoldText = ({ children, style }: TextProps) => {
  const textStyle = {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    ...style,
  };

  return <Text style={textStyle}>{children}</Text>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BoldHorizontalLine = ({ style }: { style?: any }) => {
  const lineStyle = {
    borderColor: 'black',
    marginBottom: 2,
    width: '100%',
    ...style,
  };
  return <View style={lineStyle} />;
};

export const ItalicText = ({ children, style }: TextProps) => {
  const textStyle = {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    ...style,
  };

  return <Text style={textStyle}>{children}</Text>;
};

interface TableProps {
  headers: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>[];
  showHeader?: boolean;
}

export const Table = ({ headers, data, showHeader = false }: TableProps) => {
  return (
    <View style={pdfStyles.table}>
      {showHeader && (
        <View style={pdfStyles.tableRow}>
          {headers.map((header, index) => (
            <Text
              key={index}
              style={{
                ...pdfStyles.tableHeader,
                width: `${100 / headers.length}%`,
              }}
            >
              {header}
            </Text>
          ))}
        </View>
      )}

      {data.map((row, rowIndex) => {
        return (
          <View key={rowIndex} style={pdfStyles.tableRow}>
            {headers.map((header, colIndex) => (
              <Text
                key={colIndex}
                style={{
                  ...pdfStyles.tableCol,
                  width: `${100 / headers.length}%`,
                }}
              >
                {row[header]}
              </Text>
            ))}
          </View>
        );
      })}
    </View>
  );
};

interface ListProps {
  items: string[];
}

export const List = ({ items }: ListProps) => (
  <View>
    {items.map((item, index) => (
      <View key={index} style={pdfStyles.listItem}>
        <Text>{index + 1}.</Text>
        <Text style={pdfStyles.listItemText}>{item}</Text>
      </View>
    ))}
  </View>
);
