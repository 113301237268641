import { Document, PDFViewer } from '@react-pdf/renderer';
import { useAppSelector } from '../../../store/hooks';
import InvoiceFormat from './_InvoiceFormat';

const InvoicePDF = () => {
  const { invoiceData } = useAppSelector((state) => state.invoice);

  return (
    <div className="w-screen h-screen">
      <PDFViewer width="70%" height="90%">
        <Document>
          {invoiceData && <InvoiceFormat data={invoiceData} />}
        </Document>
      </PDFViewer>
    </div>
  );
};

export default InvoicePDF;
