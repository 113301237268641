import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IFreelanceRequest {
  name: string;
  mobile: string;
  location: string;
  companyName: string;
  designation: string;
  totalExperience: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  docId: string;
  documents: {
    title: string;
    type: string;
    url: string;
  }[];
}

export interface LocationsState {
  pendingFreelanceRequests: IFreelanceRequest[];
  approvedFreelanceRequests: IFreelanceRequest[];
  rejectedFreelanceRequests: IFreelanceRequest[];
}

const initialState: LocationsState = {
  pendingFreelanceRequests: [],
  approvedFreelanceRequests: [],
  rejectedFreelanceRequests: [],
};

export const freelanceRequestSlice = createSlice({
  name: 'freelanceRequest',
  initialState,
  reducers: {
    setPendingFreelanceRequests(
      state,
      action: PayloadAction<LocationsState['pendingFreelanceRequests']>
    ) {
      state.pendingFreelanceRequests = action.payload;
    },
    setApprovedFreelanceRequests(
      state,
      action: PayloadAction<LocationsState['approvedFreelanceRequests']>
    ) {
      state.approvedFreelanceRequests = action.payload;
    },
    setRejectedFreelanceRequests(
      state,
      action: PayloadAction<LocationsState['rejectedFreelanceRequests']>
    ) {
      state.rejectedFreelanceRequests = action.payload;
    },
  },
});

export const {
  setPendingFreelanceRequests,
  setApprovedFreelanceRequests,
  setRejectedFreelanceRequests,
} = freelanceRequestSlice.actions;

export default freelanceRequestSlice.reducer;
