import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
import { IAssignUser } from '../hooks/useCampaigns';

export interface ICampaign {
  campaignId: string;
  title: string;
  timeframe: number;
  manager: IAssignUser;
  executives: string[];
  status: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  createdBy: string;
}

interface CampaignState {
  campaigns: ICampaign[];
  campaignDataCount: number;
}

export interface ExcelData {
  name?: string;
  mobile?: string | number;
  dialerStatus?: number | null;
  userId: string | null;
}

const initialState: CampaignState = {
  campaigns: [],
  campaignDataCount: 0,
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaigns(state, action: PayloadAction<CampaignState['campaigns']>) {
      state.campaigns = action.payload;
    },
    setCampaignDataCount(
      state,
      action: PayloadAction<CampaignState['campaignDataCount']>
    ) {
      state.campaignDataCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCampaigns, setCampaignDataCount } = campaignSlice.actions;

export default campaignSlice.reducer;
