import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface ILoan {
  bankName: string;
  createdAt: Date;
  dateOfLoan: string;
  docId: string;
  emiAmount: number;
  emiDate: Date;
  loanAmount: number;
  loanType: string;
  reminderDate: Date;
  roi: number;
  tenure: number;
  updatedAt: Date;
}

interface Entity {
  id: number;
  nickname: string;
}

interface SanctionLetter {
  type: string;
  url: string;
}

interface Staff {
  id: number;
  mobile: string;
  name: string;
}

export interface EntityApplication {
  applicationId: string;
  createdAt: Timestamp;
  eligibility: {
    dealAmount: number;
    emi: number;
    loanAmount: number;
    processingFees: number;
    roi: number;
    tenure: number;
  };
  entity: Entity;
  leadId: string;
  leadName: string;
  loginTime: Timestamp;
  opsExecutive: string;
  opsManager: string;
  parentApplication: string;
  priority: string;
  sanctionLetter: SanctionLetter;
  serviceType: string;
  staff: Staff;
  status: string;
  updatedAt: Timestamp;
  docId: string;
}

interface EntityApplicationState {
  entityApplications: EntityApplication[];
  uniqueApplications: EntityApplication[];
}

const initialState: EntityApplicationState = {
  entityApplications: [],
  uniqueApplications: [],
};

const entityApplicationSlice = createSlice({
  name: 'entityApplication',
  initialState,
  reducers: {
    resetLoans: () => initialState,
    setEntityApplications(
      state,
      action: PayloadAction<EntityApplicationState['entityApplications']>
    ) {
      state.entityApplications = action.payload;
    },
    setUniqueEntityApplications(
      state,
      action: PayloadAction<EntityApplicationState['uniqueApplications']>
    ) {
      state.uniqueApplications = action.payload;
    },
  },
});

export const {
  setEntityApplications,
  setUniqueEntityApplications,
  resetLoans,
} = entityApplicationSlice.actions;

export default entityApplicationSlice.reducer;
