import { CheckCircleRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import TitleValue from '../../../components/TitleValue';
import useFinancialDoctor from '../../../hooks/useFinancialDoctor';
import {
  IDocuments,
  IExperience,
  ISkill,
  ISlot,
} from '../../../store/doctors.store';
import { useAppSelector } from '../../../store/hooks';
import { formatDate } from '../../../utils';
import { formatCurrency, formatMobile } from '../../../utils/utils';
import RejectionDialog from './_RejectionDialog';

const DoctorDetailsPage = () => {
  const params = useParams();
  const search = useLocation();
  const { id } = params;

  const { pendingDoctors, approvedDoctors } = useAppSelector(
    (state) => state.doctors
  );
  const {
    updateDoctorStatus,
    getExperience,
    getSkills,
    getSlots,
    getDocuments,
  } = useFinancialDoctor();

  const [experience, setExperience] = useState<IExperience[]>([]);
  const [skills, setSkills] = useState<ISkill | undefined>();
  const [slots, setSlots] = useState<Record<string, ISlot[]> | undefined>();
  const [documents, setDocuments] = useState<IDocuments[]>([]);
  const [openDialog, setOpenDialog] = useState<
    { uid: string; mobile: string } | undefined
  >();

  const doctors = search.search.includes('pending')
    ? pendingDoctors
    : approvedDoctors;

  const doctor = doctors.find((doc) => doc.uid == id);

  useEffect(() => {
    if (doctor) {
      getExperience(formatMobile(doctor.mobile)).then((e) => setExperience(e));
    }
  }, [doctor, getExperience]);

  useEffect(() => {
    if (doctor) {
      getSkills(formatMobile(doctor.mobile)).then((e) => setSkills(e));
    }
  }, [doctor, getSkills]);

  useEffect(() => {
    if (doctor) {
      getSlots(formatMobile(doctor.mobile)).then((e) => setSlots(e));
    }
  }, [doctor, getSlots]);

  useEffect(() => {
    if (doctor) {
      getDocuments(formatMobile(doctor.mobile)).then((e) => setDocuments(e));
    }
  }, [doctor, getDocuments]);

  return !doctor ? null : (
    <Paper sx={{ padding: '1rem' }}>
      <Box>
        <Box sx={{ marginBottom: '2rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Personal Info
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '30px',
            }}
          >
            <TitleValue title="Name" value={doctor?.leadName ?? '-'} />
            <TitleValue title="Mobile Number" value={doctor?.mobile ?? '-'} />
            <TitleValue
              title="Alt Mobile Number"
              value={doctor?.altMobile ?? '-'}
            />
            <TitleValue title="Location" value={doctor?.location ?? '-'} />
            <TitleValue title="Mail Id" value={doctor?.email ?? '-'} />
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Bio</Typography>
            <Typography>{doctor?.bio}</Typography>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: '#3B5998' }}>
            Languages
          </Typography>

          <Box>
            {doctor?.languages && (
              <Typography>{doctor?.languages?.join(', ')}</Typography>
            )}
          </Box>
        </Box>

        {/* Professional */}
        <Box sx={{ marginBottom: '2rem', marginTop: '2rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Professional Info
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            {experience.map((e) => (
              <Card key={e.docId}>
                <CardContent>
                  <Typography variant="body1" component="div">
                    {e.companyName}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {e.designation}
                  </Typography>
                  <Typography variant="body2">
                    From: {formatDate(e.startDate.toString())} To:{'  '}
                    {e.endDate ? formatDate(e.endDate?.toString()) : 'Present'}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </Box>

        <Box sx={{ marginBottom: '2rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Skills
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            {skills?.skills.map((e) => {
              const details = skills.skillsInfo[e];

              return !details ? null : (
                <Card key={e}>
                  <CardContent>
                    <Typography variant="body1" component="div">
                      {e}
                    </Typography>
                    {details?.banks && (
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Banks: {details?.banks?.join(', ')}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      Duration: {details.duration}
                    </Typography>
                    <Typography variant="body2">
                      Prince: {formatCurrency(details.price)}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </Box>

        <Box sx={{ marginBottom: '2rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Slots
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            {[
              'monday',
              'tuesday',
              'wednesday',
              'thursday',
              'friday',
              'saturday',
              'sunday',
            ].map((e) => {
              const details = slots?.[e];

              return !details ? null : (
                <Card key={e}>
                  <CardContent>
                    <Typography variant="body1" component="div">
                      {e}
                    </Typography>
                    {details.map((f) => (
                      <div key={f.startTime}>
                        {f.startTime} - {f.endTime}
                      </div>
                    ))}
                  </CardContent>
                </Card>
              );
            })}
          </div>
        </Box>

        {/* Languages and Uploaded Documents */}
        <Box sx={{ display: 'flex', gap: '6rem', marginBottom: '2rem' }}>
          {/* Languages */}

          {/* Uploaded Documents */}
          <Box>
            <Typography variant="h6" sx={{ color: '#3B5998' }}>
              Uploaded Documents
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              {documents?.length === 0 ? (
                <Typography>No Documents were upload</Typography>
              ) : (
                documents?.map((doc, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      gap: '0.5rem',
                      minWidth: '150px',
                    }}
                  >
                    <Typography>{doc.name}</Typography>
                    <Typography
                      sx={{
                        backgroundColor: '#17AE2F',
                        color: '#fff',
                        padding: '0.3rem 1rem ',
                        display: 'flex',
                        gap: '.5rem',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      <CheckCircleRounded sx={{ fontSize: 'small' }} />
                      Uploaded
                    </Typography>
                    <Button
                      href={doc.url}
                      target="_blank"
                      size="small"
                      sx={{
                        textTransform: 'capitalize',
                        background: '#fff',
                        color: '#000',
                        border: '1px solid #00000066',
                      }}
                    >
                      View
                    </Button>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
        {openDialog && (
          <RejectionDialog
            uid={openDialog.uid}
            mobile={openDialog.mobile}
            onClose={() => setOpenDialog(undefined)}
          />
        )}
        <Box>
          {doctor?.verificationStatus !== 'Approved' && (
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                className="bg-red"
                size="small"
                variant="contained"
                onClick={() => {
                  setOpenDialog({ uid: doctor.uid, mobile: doctor.mobile });
                }}
              >
                Reject
              </Button>
              <Button
                className="bg-green"
                variant="contained"
                size="small"
                onClick={async () => {
                  updateDoctorStatus(
                    formatMobile(doctor.mobile),
                    doctor.uid,
                    'Approved'
                  );
                }}
              >
                Approve
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default DoctorDetailsPage;
