import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFinancialDoctor from '../../../hooks/useFinancialDoctor';
import { useAppSelector } from '../../../store/hooks';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { formattedDate } from '../../../utils/utils';
import { CheckCircleRounded } from '@mui/icons-material';
import { IDocuments } from '../../../store/doctors.store';
import DocPreview from '../../../components/FinancialDoctor/DocPreview';

const AppointmentBookingStatusPage = () => {
  const { id } = useParams();
  const { getBookings } = useFinancialDoctor();
  const [previewModal, setPreviewModal] = useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<IDocuments>();
  const handleOpenPreviewDoc = (doc: IDocuments) => {
    setPreviewModal(true);
    setSelectedDoc(doc);
  };

  const bookings = useAppSelector((state) => state.doctors.bookings);
  const bookingDetail = bookings.find((booking) => booking.docId === id);

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Typography
        variant="h6"
        sx={{ color: '#3B5998', marginBottom: '1rem', marginLeft: '1rem' }}
        fontWeight={'bold'}
      >
        Status - {bookingDetail?.status}
      </Typography>
      <Paper>
        <Box sx={{ padding: '1rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Client Details
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              rowGap: '1.5rem',
            }}
          >
            <Box>
              <Typography fontWeight={'bold'}>Name</Typography>
              <Typography>
                {bookingDetail?.info?.clientInfo?.name ?? 'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Contact no</Typography>
              <Typography>{bookingDetail?.client ?? 'Not Found'}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Mail Id</Typography>
              <Typography>
                {bookingDetail?.info?.clientInfo?.email ?? 'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Profession</Typography>
              <Typography>
                {bookingDetail?.info?.clientInfo?.profession ?? 'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Language</Typography>
              <Typography>
                {bookingDetail?.info?.clientInfo?.selectedLanguages ??
                  'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Issue Type</Typography>
              <Typography>{bookingDetail?.issue ?? 'Not Found'}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Bank</Typography>
              <Typography>
                {bookingDetail?.info?.clientInfo?.selectedBank ?? 'Not Found'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography fontWeight={'bold'}>Issue Description</Typography>
            <Typography>
              {bookingDetail?.info?.description ?? 'Not Found'}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Box sx={{ marginBottom: '2rem', padding: '1rem' }}>
            <Typography
              variant="h6"
              sx={{ color: '#3B5998', marginBottom: '1rem' }}
            >
              Booking Details
            </Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                rowGap: '1.5rem',
              }}
            >
              <Box>
                <Typography fontWeight={'bold'}>Doctor Name</Typography>
                <Typography>
                  {bookingDetail?.info?.doctorInfo?.name ?? 'Not Found'}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>Dctor ID</Typography>
                <Typography>{bookingDetail?.doctor ?? 'Not Found'}</Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>Booking Date & Time</Typography>
                <Typography>
                  {(bookingDetail?.createdAt !== undefined &&
                    formattedDate(bookingDetail?.createdAt?.toDate())) ??
                    'Not Found'}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>Booking ID</Typography>
                <Typography>
                  {bookingDetail?.bookingId ?? 'Not Found'}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>Duration</Typography>
                <Typography>
                  {bookingDetail?.info?.duration ?? 'Not Found'}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>Amount Paid</Typography>
                <Typography>
                  {bookingDetail?.info?.amount ?? 'Not Found'}.00
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight={'bold'}>
                  Appointment Date & Time
                </Typography>
                <Typography>
                  {(bookingDetail?.timeSlot !== undefined &&
                    formattedDate(new Date(bookingDetail?.timeSlot))) ??
                    'Not Found'}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: '1rem' }}>
              {bookingDetail?.status === 'Completed' ||
                (bookingDetail?.status === 'Accepted' && (
                  <>
                    <Typography fontWeight={'bold'}>Suggestion</Typography>
                    <Typography>
                      {bookingDetail?.prescribe
                        ?.flatMap((pres) =>
                          pres.requirements.map((suggest) => suggest)
                        )
                        .join(', ') || 'Not Found'}
                    </Typography>
                  </>
                ))}
            </Box>

            <Box sx={{ marginTop: '1rem' }}>
              {bookingDetail?.status === 'Rejected' && (
                <>
                  <Typography fontWeight={'bold'}>Rejection Reason</Typography>
                  <Typography>
                    {bookingDetail?.rejections?.map(
                      (reject) => reject.reason
                    ) || 'Not  Found'}
                  </Typography>
                </>
              )}
            </Box>

            <Box sx={{ marginTop: '1rem' }}>
              {bookingDetail?.status === 'Cancelled' && (
                <>
                  <Typography fontWeight={'bold'}>Rejection Reason</Typography>
                  <Typography>
                    {bookingDetail?.cancelled || 'Not defined'}
                  </Typography>
                </>
              )}
            </Box>
            <Box sx={{ marginTop: '2rem' }}>
              <Typography variant="h6" sx={{ color: '#3B5998' }}>
                Uploaded Documents
              </Typography>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                {bookingDetail?.documents?.length === 0 ? (
                  <Typography>No Documents were upload</Typography>
                ) : (
                  bookingDetail?.documents?.map((doc, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <Typography>{doc.name}</Typography>
                      <Typography
                        sx={{
                          backgroundColor: '#17AE2F',
                          color: '#fff',
                          padding: '0.3rem 0.5rem',
                          fontSize: '0.8rem',
                          display: 'flex',
                          gap: '.5rem',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '.5rem',
                        }}
                      >
                        <CheckCircleRounded sx={{ fontSize: '1rem' }} />
                        Uploaded
                      </Typography>
                      <Button
                        size="small"
                        sx={{
                          fontSize: '0.8rem',
                          textTransform: 'capitalize',
                          background: '#fff',
                          color: '#000',
                          border: '1px solid #00000066',
                        }}
                        onClick={() => handleOpenPreviewDoc(doc)}
                      >
                        View
                      </Button>
                    </Box>
                  ))
                )}
              </Box>

              {previewModal && (
                <DocPreview
                  open={previewModal}
                  onClose={() => setPreviewModal(false)}
                  docData={selectedDoc}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AppointmentBookingStatusPage;
