import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useCallback } from 'react';
import { driveInnFirestoreDb, fdFirestoreDb, firestoreDb } from '../firebase';
import { firebaseError } from '../firebase/utils';
import {
  IBooking,
  IDocAddress,
  IDocuments,
  IEarnings,
  IExperience,
  IFinancialDoctor,
  ISkill,
  ISlot,
  setApprovedDoctors,
  setBookings,
  setEarnings,
  setPendingDoctors,
} from '../store/doctors.store';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setShowBackdrop, setShowSnackbar } from '../store/ui.store';
import { PaginationUtil } from '../utils/PaginationUtil';
import { Pagination } from '../utils/types';

const useFinancialDoctor = () => {
  const dispatch = useAppDispatch();
  const { approvedDoctors, pendingDoctors } = useAppSelector(
    (state) => state.doctors
  );

  const getApprovedDoctors = useCallback(async () => {
    try {
      if (approvedDoctors.length !== 0) return;

      dispatch(setShowBackdrop(true));

      const q = query(
        collection(firestoreDb, 'leads'),
        where('isDoctor', '==', true),
        where('verificationStatus', '==', 'Approved')
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data() } as IFinancialDoctor)
      );

      dispatch(setApprovedDoctors(data));
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          msg: `${firebaseError(error)}`,
          type: 'error',
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  }, [dispatch, approvedDoctors.length]);

  const getPendingDoctors = useCallback(async () => {
    try {
      if (pendingDoctors.length !== 0) return;

      dispatch(setShowBackdrop(true));

      const q = query(
        collection(firestoreDb, 'leads'),
        where('isDoctor', '==', true),
        where('verificationStatus', '!=', 'Approved')
      );
      const querySnapshot = await getDocs(q);

      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), uid: e.id } as IFinancialDoctor)
      );

      dispatch(setPendingDoctors(data));
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          msg: `${firebaseError(error)}`,
          type: 'error',
        })
      );
    } finally {
      dispatch(setShowBackdrop(false));
    }
  }, [dispatch, pendingDoctors.length]);

  const getDoctorById = useCallback(async (mobile: string) => {
    const docSnap = await getDoc(doc(firestoreDb, 'leads', mobile));
    const doctor = {
      ...docSnap.data(),
      uid: docSnap.id,
    } as IFinancialDoctor;
    return doctor;
  }, []);

  const getExperience = useCallback(async (mobile: string) => {
    const q = query(collection(firestoreDb, `leads/${mobile}/experience`));
    const snapshot = await getDocs(q);
    const experience = snapshot.docs.map(
      (e) => ({ ...e.data(), docId: e.id } as IExperience)
    );
    experience.sort(function (a, b) {
      const c = new Date(a.startDate);
      const d = new Date(b.startDate);
      return d.getTime() - c.getTime();
    });

    return experience;
  }, []);

  const getAddress = useCallback(async (mobile: string) => {
    const q = query(collection(firestoreDb, `leads/${mobile}/address`));
    const snapshot = await getDocs(q);

    return snapshot.docs.map((e) => e.data() as IDocAddress);
  }, []);

  const getDocuments = useCallback(async (mobile: string) => {
    const q = query(collection(firestoreDb, `leads/${mobile}/documents`));
    const snapshot = await getDocs(q);
    const docs = snapshot.docs.filter((e) => !e.data()['groupName']);

    return docs.map((e) => e.data() as IDocuments);
  }, []);

  const getSlots = useCallback(async (mobile: string) => {
    const snap = await getDoc(doc(firestoreDb, `leads/${mobile}/slots/slots`));
    const slots = snap.data() as Record<string, ISlot[]>;

    return slots;
  }, []);

  const getSkills = useCallback(async (userId: string) => {
    const snap = await getDoc(doc(fdFirestoreDb, 'skills', userId));

    return snap.data() as ISkill;
  }, []);

  const isUsernameAvailable = useCallback(async (username: string) => {
    const snap = await getDoc(
      doc(driveInnFirestoreDb, 'usernameAvailability', username)
    );
    return !snap.exists();
  }, []);

  const generateUniqueUsername = useCallback(
    async (displayName: string) => {
      const normalizedName = displayName.replaceAll(' ', '_').toLowerCase();

      let username = normalizedName;
      let counter = 1;

      while (!(await isUsernameAvailable(username))) {
        username = `${normalizedName}${counter}`;
        counter++;
      }
      return username;
    },
    [isUsernameAvailable]
  );

  const addUsernameIfNotAvailable = useCallback(
    async (uid: string, displayName: string, avatar?: string) => {
      try {
        const snap = await getDoc(doc(fdFirestoreDb, 'users_min', uid));
        const username = snap.exists() ? snap.data()?.['username'] : undefined;
        if (username) return undefined;
        const newUsername = await generateUniqueUsername(displayName);
        await setDoc(
          doc(driveInnFirestoreDb, `usernameAvailability/${newUsername}`),
          { uid: uid }
        );
        await setDoc(
          doc(fdFirestoreDb, `users_min/${uid}`),
          { avatar: avatar, name: displayName, username: newUsername },
          { merge: true }
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('hasUsername ERROR', error);
      }
    },
    [generateUniqueUsername]
  );

  const updateDoctorStatus = useCallback(
    async (mobile: string, uid: string, status: string, reason?: string) => {
      dispatch(setShowBackdrop(true));
      try {
        await updateDoc(doc(firestoreDb, `leads/${mobile}`), {
          verificationStatus: status,
          updatedAt: serverTimestamp(),
        });

        if (status === 'Rejected') {
          await addDoc(
            collection(firestoreDb, `leads/${mobile}/rejectedReasons`),
            {
              reason: reason,
              createdAt: serverTimestamp(),
            }
          );
        }

        if (status === 'Approved') {
          const doctor = pendingDoctors.find((e) => e.uid === mobile);
          const updated = {
            ...doctor,
            verificationStatus: status,
            updatedAt: serverTimestamp(),
          } as IFinancialDoctor;

          await addUsernameIfNotAvailable(
            uid,
            doctor?.leadName ?? '',
            doctor?.avatar
          );

          dispatch(setApprovedDoctors([...approvedDoctors, updated]));
        }
        const updated = pendingDoctors.map((c) =>
          c.uid === uid ? { ...c, verificationStatus: status } : c
        );
        dispatch(setPendingDoctors(updated));

        dispatch(
          setShowSnackbar({
            open: true,
            type: 'success',
            msg:
              status === 'Approved'
                ? 'Approve Successfully!'
                : 'Rejected Successfully!',
          })
        );
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            type: 'error',
            msg: `${firebaseError(error)}`,
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    [addUsernameIfNotAvailable, approvedDoctors, dispatch, pendingDoctors]
  );

  interface BookingProps extends Pagination {
    mobile?: string;
    startDate?: Date;
    endDate?: Date;
    bookingId?: string;
    status?: string;
  }

  const getBookings = useCallback(
    async (filter?: Partial<BookingProps>) => {
      try {
        dispatch(setShowBackdrop(true));

        const conditions = [];
        const coll = collection(fdFirestoreDb, 'bookings');

        if (filter?.bookingId) {
          conditions.push(where('bookingId', '==', filter.bookingId));
        }
        if (filter?.status && filter?.status !== 'All') {
          conditions.push(where('status', '==', filter.status));
        }
        if (filter?.mobile) {
          conditions.push(where('doctor', '==', filter.mobile));
        }
        if (filter?.startDate) {
          conditions.push(where('createdAt', '>=', filter.startDate));
        }
        if (filter?.endDate) {
          conditions.push(where('createdAt', '<=', filter.endDate));
        }
        conditions.push(orderBy('createdAt', 'desc'));

        if (filter?.startAt) {
          conditions.push(startAfter(filter.startAt));
        }

        conditions.push(limit(10));

        // Build the query with conditional where clauses
        const q = query(coll, ...conditions);
        const querySnapshot = await getDocs(q);

        const { lastDoc, total, hasMore } = await PaginationUtil(
          coll,
          conditions,
          querySnapshot.docs
        );

        const data = querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              docId: doc.id,
              total: total,
              startAt: lastDoc,
              hasMore,
            } as IBooking)
        );
        window.console.log('AAAA1', data);
        dispatch(setBookings(data));
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: `${firebaseError(error)}`,
            type: 'error',
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    [dispatch]
  );

  const getBookingsByClient = useCallback(async (client: string) => {
    try {
      const q = query(
        collection(fdFirestoreDb, 'bookings'),
        where('client', '==', client)
      );
      const snapshot = await getDocs(q);

      const lastBookingSnapshot = snapshot.docs[snapshot.docs.length - 1];
      const bookingData = lastBookingSnapshot.data() as IBooking;
      const docId = lastBookingSnapshot.id;
      return { ...bookingData, docId };
    } catch (error) {
      // TODO:
    }
  }, []);

  const getDocumentsByClient = useCallback(async (docId: string) => {
    try {
      const q = query(collection(fdFirestoreDb, `bookings/${docId}/documents`));

      const snapshot = await getDocs(q);
      return snapshot.docs.map((e) => e.data() as IDocuments);
    } catch (error) {
      // TODO:
    }
  }, []);

  const getEarnings = useCallback(
    async (userId: string) => {
      try {
        dispatch(setShowBackdrop(true));
        const q = query(
          collection(fdFirestoreDb, 'earnings'),
          where('userId', '==', userId)
        );
        const querySnapshot = await getDocs(q);
        const data: IEarnings[] = querySnapshot.docs.map(
          (doc) => ({ ...doc.data(), uid: doc.id } as IEarnings)
        );

        dispatch(setEarnings(data));
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: `${firebaseError(error)}`,
            type: 'error',
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    [dispatch]
  );

  return {
    getBookings,
    getBookingsByClient,
    getDocumentsByClient,
    getDoctorById,
    getEarnings,
    getSkills,
    getApprovedDoctors,
    getPendingDoctors,
    updateDoctorStatus,
    getExperience,
    getAddress,
    getDocuments,
    getSlots,
  };
};

export default useFinancialDoctor;
