import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';
// Import the interface from your component

export interface InvoiceType {
  id?: string;
  uid?: string;
  name: string;
  date: string;
  invoiceNo: string;
  GSTNo: string;
  PAN: string;
  items: {
    description: string;
    doj: string;
    serviceAmount: string;
    payableAmount: string;
  }[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface InvoiceState {
  invoiceData?: InvoiceType;
  invoices?: InvoiceType[];
}

const initialState: InvoiceState = { invoices: [] };

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoiceData(state, action: PayloadAction<InvoiceState['invoiceData']>) {
      state.invoiceData = action.payload;
    },
    setInvoices(state, action: PayloadAction<InvoiceState['invoices']>) {
      state.invoices = action.payload;
    },
  },
});

export const { setInvoiceData, setInvoices } = invoiceSlice.actions;

export default invoiceSlice.reducer;
