import { Timestamp } from 'firebase/firestore';

export function timestampToDate(timestamp: Timestamp) {
  // Make sure timestamp is a Firestore Timestamp
  if (!timestamp || !timestamp.seconds) {
    return null;
  }

  const seconds = timestamp.seconds;
  const nanoseconds = timestamp.nanoseconds;

  // Create a new Date object using the seconds and nanoseconds
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  // Extract individual components of the date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear().toString();

  // Format the date in DD-MM-YYYY format
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function timestampToDateForDatePicker(value?: any) {
  let timestamp = value;
  // Make sure timestamp is a Firestore Timestamp
  if (!timestamp || !timestamp.seconds) {
    timestamp =
      typeof value === 'string'
        ? Timestamp.fromDate(new Date(value))
        : Timestamp.now();
  }

  const seconds = timestamp.seconds;
  const nanoseconds = timestamp.nanoseconds;

  // Create a new Date object using the seconds and nanoseconds
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  // Extract individual components of the date
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear().toString();

  // Format the date in YYYY-MM-DD format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function daysBetweenDates(leaveFrom: Timestamp, leaveTo: Timestamp) {
  if (!leaveFrom || !leaveTo) {
    return null;
  }

  // Create Date objects from the Firestore Timestamps
  const leaveFromDate = new Date(
    leaveFrom.seconds * 1000 + leaveFrom.nanoseconds / 1000000
  );
  const leaveToDate = new Date(
    leaveTo.seconds * 1000 + leaveTo.nanoseconds / 1000000
  );

  // Calculate the time difference in milliseconds between the two dates
  const timeDifference = leaveToDate.getTime() - leaveFromDate.getTime();

  // Convert milliseconds to days
  const days = timeDifference / (1000 * 60 * 60 * 24);

  // Return the absolute value of days, as we already used Math.abs to handle negative noOfDays
  return Math.abs(days + 1);
}

export function formatDate(dateString: string) {
  // Step 1: Create a new Date object from the date string
  const dateObj = new Date(dateString);

  // Step 2: Extract individual components of the date
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
  const day = dateObj.getDate().toString().padStart(2, '0');

  // Step 3: Format the date in the desired format (YYYY-MM-DD HH:mm:ss)
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

export const downloadCode = (id: string, title: string) => {
  const canvas = document.getElementById(id) as HTMLCanvasElement;
  if (canvas) {
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${title.toLowerCase().replaceAll(' ', '_')}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};
