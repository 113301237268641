import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

interface ForField {
  [key: string]: unknown;
}

export interface IQuiz {
  title: string;
  quizType: string;
  createdAt: Timestamp;
  endTime: Timestamp;
  startTime: Timestamp;
  for: ForField;
  [key: string]: unknown;
  docId: string;
}

export interface LocationsState {
  postedQuiz: IQuiz[];
}

const initialState: LocationsState = {
  postedQuiz: [],
};

export const quizsSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setPostedQuiz(state, action: PayloadAction<LocationsState['postedQuiz']>) {
      state.postedQuiz = action.payload;
    },
  },
});

export const { setPostedQuiz } = quizsSlice.actions;

export default quizsSlice.reducer;
