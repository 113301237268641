import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#3B5998',
    },
    secondary: {
      main: '#86C421',
    },
    error: {
      main: red.A400,
    },
  },
  // typography: {
  //   fontFamily: 'Montserrat', // Change this to your desired font family
  // },
});

export default theme;
