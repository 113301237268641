import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../firebase';

const useFileUpload = () => {
  const uploadFile = async (file: File, path: string) => {
    const storageRef = ref(storage, path);
    // const uploadTask = uploadBytesResumable(storageRef, file);

    return uploadBytes(storageRef, file).then(async (snapshot) => {
      return {
        url: await getDownloadURL(snapshot.ref).then(
          (downloadURL) => downloadURL
        ),
        type: `${snapshot.metadata.contentType}`,
      };
    });
  };

  return uploadFile;
};

export default useFileUpload;
