import { AlertColor } from '@mui/material';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface UiState {
  paused: boolean;
  showBackdrop: boolean;
  snackbar: {
    open: boolean;
    type?: AlertColor;
    msg?: string;
  };
  deleteDialog: {
    show: boolean;
    onDelete?: () => void;
  };
  tablePagination: {
    page: number;
    rowsPerPage: number;
  };
}

const initialState: UiState = {
  paused: false,
  showBackdrop: false,
  snackbar: {
    open: false,
  },
  deleteDialog: { show: false },
  tablePagination: {
    page: 0,
    rowsPerPage: 10,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPaused(state, action: PayloadAction<UiState['paused']>) {
      state.paused = action.payload;
    },
    setShowBackdrop(state, action: PayloadAction<UiState['showBackdrop']>) {
      state.showBackdrop = action.payload;
    },
    setShowSnackbar(state, action: PayloadAction<UiState['snackbar']>) {
      state.snackbar = action.payload;
    },
    setDeleteDialog(state, action: PayloadAction<UiState['deleteDialog']>) {
      state.deleteDialog = action.payload;
    },
    setTablePagination(
      state,
      action: PayloadAction<UiState['tablePagination']>
    ) {
      state.tablePagination = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPaused,
  setDeleteDialog,
  setShowBackdrop,
  setShowSnackbar,
  setTablePagination,
} = uiSlice.actions;

export default uiSlice.reducer;
