import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import InputField from '../../components/InputField';
import { Info, Search } from '@mui/icons-material';

const data = [
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
  {
    eventTitle: 'Satyanarayana',
    location: 'Community Hall',
    date: '12-12-2023',
  },
];

const ClientCommunityEventsPage = () => {
  const [currentStatus, setCurrentStatus] = useState('Completed');
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  const status = ['Upcoming', 'Completed', 'Cancelled', 'Rejected'];

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentStatus(event.target.value);
  };

  const headCell = ['Event Title', 'Location', 'Date', 'Details'];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 4fr',
          alignItems: 'center',
          paddingBottom: '1rem',
        }}
      >
        <Typography variant="h6" sx={{ color: '#3B5998' }}>
          Community Events
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '4fr 3fr 3fr 1fr',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <FormControl size="small">
            <InputLabel id="demo-select-small-label">Select Type</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={currentStatus}
              label="Select Type"
              onChange={handleChange}
            >
              {status.map((value, i) => (
                <MenuItem value={value} key={i}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small">
            <InputField
              type="date"
              label="Start Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl size="small">
            <InputField
              type="date"
              label="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <Search sx={{ cursor: 'pointer' }} />
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '1rem',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headCell.map((row, i) => (
                <TableCell
                  key={i}
                  sx={{ color: '#3B5998', fontWeight: 'bold' }}
                  align="center"
                >
                  {row}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell align="center">{row.eventTitle}</TableCell>
                <TableCell align="center">{row.location}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">
                  <Info color="primary" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ClientCommunityEventsPage;
