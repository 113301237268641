import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface ILeads {
  address: string;
  createdAt: string;
  createdBy: string;
  designation: string;
  dob: string;
  isClient: boolean;
  leadName: string;
  mobile: string;
  occupation: string;
  pincode: string;
  source: string;
  mailId?: string;
  updatedAt: Timestamp;
  docId?: string;
}

export interface LocationsState {
  leads: ILeads[];
}

const initialState: LocationsState = {
  leads: [],
};

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeads(state, action: PayloadAction<LocationsState['leads']>) {
      state.leads = action.payload;
    },
  },
});

export const { setLeads } = leadsSlice.actions;

export default leadsSlice.reducer;
