import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IMarketingStrategy {
  docId?: string;
  info: Info;
  timeline: Timeline;
  communicate: Communicate[];
  costing: Costing;
  autoAllocation: boolean;
  assignees: string[];
  removedAssignees: string[];
}

export interface IProspectCount {
  pending: number;
  assigned: number;
  completed: number;
}

export interface Communicate {
  name: string;
  designation: string;
  mobile: string;
}

export interface Costing {
  type: string;
  value: number;
  bankDetails: BankDetail[];
}

export interface BankDetail {
  nickname: string;
  accountHolderName: string;
  bankName: string;
  accountNo: string;
  ifscCode: string;
}

export interface Info {
  title: string;
  companyName: string;
  location: string;
  city: string;
  state: string;
  pincode: string;
  programType: string;
  url: string;
  description: string;
}

export interface Timeline {
  startDate: Date;
  endDate: Date;
  frequency: string;
}

export interface IExpense {
  docId: string;
  title: string;
  purpose: string;
  date: Timestamp;
  paidTo: string;
  paidBy: string;
  paymentType: string;
  transactionType: string;
  remarks: string;
  file: {
    url: string;
    type: string;
  };
}

export interface MarketingStrategiesState {
  data: IMarketingStrategy[];
  transactions: IExpense[];
}

const initialState: MarketingStrategiesState = {
  data: [],
  transactions: [],
};

export const marketingStrategiesSlice = createSlice({
  name: 'marketingStrategies',
  initialState,
  reducers: {
    setMarketingStrategies(
      state,
      action: PayloadAction<MarketingStrategiesState['data']>
    ) {
      state.data = action.payload;
    },
    setTransactions(
      state,
      action: PayloadAction<MarketingStrategiesState['transactions']>
    ) {
      state.transactions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMarketingStrategies, setTransactions } =
  marketingStrategiesSlice.actions;

export default marketingStrategiesSlice.reducer;
