import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import useFinancialDoctor from '../../../hooks/useFinancialDoctor';
import { Box, Divider, Paper, Typography } from '@mui/material';
import { formattedDate } from '../../../utils/utils';

const AppointmentBookingDetailPage = () => {
  const { id } = useParams();
  const { getBookings } = useFinancialDoctor();

  const bookings = useAppSelector((state) => state.doctors.bookings);
  const bookingDetail = bookings.find((booking) => booking.docId === id);

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <Box sx={{ padding: '1rem' }}>
        <Typography
          variant="h6"
          sx={{ color: '#3B5998', marginBottom: '1rem' }}
        >
          Client Details
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            rowGap: '1.5rem',
          }}
        >
          <Box>
            <Typography fontWeight={'bold'}>Name</Typography>
            <Typography>
              {bookingDetail?.info?.clientInfo?.name ?? 'Not Found'}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Contact no</Typography>
            <Typography>{bookingDetail?.client ?? 'Not Found'}</Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Mail Id</Typography>
            <Typography>
              {bookingDetail?.info?.clientInfo?.email ?? 'Not Found'}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Profession</Typography>
            <Typography>
              {bookingDetail?.info?.clientInfo?.profession ?? 'Not Found'}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Language</Typography>
            <Typography>
              {bookingDetail?.info?.clientInfo?.selectedLanguages ??
                'Not Found'}
            </Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Issue Type</Typography>
            <Typography>{bookingDetail?.issue ?? 'Not Found'}</Typography>
          </Box>
          <Box>
            <Typography fontWeight={'bold'}>Bank</Typography>
            <Typography>
              {bookingDetail?.info?.clientInfo?.selectedBank ?? 'Not Found'}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography fontWeight={'bold'}>Issue Description</Typography>
          <Typography>
            {bookingDetail?.info?.description ?? 'Not Found'}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box sx={{ marginBottom: '2rem', padding: '1rem' }}>
          <Typography
            variant="h6"
            sx={{ color: '#3B5998', marginBottom: '1rem' }}
          >
            Booking Details
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              rowGap: '1.5rem',
            }}
          >
            <Box>
              <Typography fontWeight={'bold'}>Doctor Name</Typography>
              <Typography>
                {bookingDetail?.info?.doctorInfo?.name ?? 'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Dctor ID</Typography>
              <Typography>{bookingDetail?.doctor ?? 'Not Found'}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Booking Date & Time</Typography>
              <Typography>
                {(bookingDetail?.createdAt !== undefined &&
                  formattedDate(bookingDetail?.createdAt?.toDate())) ??
                  'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Booking ID</Typography>
              <Typography>{bookingDetail?.bookingId ?? 'Not Found'}</Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Duration</Typography>
              <Typography>
                {bookingDetail?.info?.duration ?? 'Not Found'}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>Amount Paid</Typography>
              <Typography>
                {bookingDetail?.info?.amount ?? 'Not Found'}.00
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={'bold'}>
                Appointment Date & Time
              </Typography>
              <Typography>
                {(bookingDetail?.timeSlot !== undefined &&
                  formattedDate(new Date(bookingDetail?.timeSlot))) ??
                  'Not Found'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default AppointmentBookingDetailPage;
