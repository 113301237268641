import { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ErrorMessage from './components/ErrorMessage';
import Loader from './components/Loader';
import useConfigurations from './hooks/useConfigurations';
import './index.css';
import ClientCommunityEventsPage from './pages/Client/ClientCommunityEventsPage';
import CommunityPreviewPage from './pages/Client/CommunityPreviewPage';
import IDCardPage from './pages/Digitalparts/IDCardPage';
import MarketingPosterPage from './pages/Digitalparts/MarketingPosterPage';
import VisitingCardPage from './pages/Digitalparts/VisitingCardPage';
import AppointmentBookingDetailPage from './pages/FinancialDoctor/AppointBookings/AppointmentBookingDetailPage';
import AppointmentBookingStatusPage from './pages/FinancialDoctor/AppointBookings/AppointmentBookingStatusPage';
import DoctorDetailsPage from './pages/FinancialDoctor/Doctor/DoctorDetailsPage';
import LeadManagementReportPage from './pages/Hrms/Leads/LeadManagementReportPage';
import MainPage from './pages/MainPage';
import PLEligibilityTest from './pages/PLEligibilityTest';
import NonServiceablePincodes from './pages/Reports/NonServiceablePincodes';
import { AuthProvider } from './provider/AuthProvider';
import { useAppDispatch } from './store/hooks';
import { setTablePagination } from './store/ui.store';
import CreateInvoice from './pages/Ops/LoanSummary/_CreateInvoice';
import InvoicePDF from './pages/Ops/LoanSummary/_InvoicePDF';

const FreelanceRequestPage = lazy(
  () => import('./pages/FreelanceRequest/FreelanceRequestPage')
);

const ClientDetailPage = lazy(
  () => import('./pages/Client/Client/ClientDetailPage')
);

const OperationsPage = lazy(() => import('./pages/Ops/OperationsPage'));
const GrowthRatePage = lazy(() => import('./pages/Ops/GrowthRatePage'));
const ScalabilityPage = lazy(() => import('./pages/Ops/ScalabilityPage'));
const OverViewPage = lazy(() => import('./pages/Ops/OverViewPage'));
const LoansSummary = lazy(() => import('./pages/Ops/LoanSummary/LoansSummary'));
const ClientLoansDetail = lazy(
  () => import('./pages/Ops/LoanSummary/_ClientLoansDetail')
);

const QuizLeaderBoard = lazy(() => import('./pages/Quizs/QuizLeaderBoard'));
const QuizPostPage = lazy(
  () => import('./pages/Quizs/QuizPosted/QuizPostPage')
);
const QuizAttemptPage = lazy(
  () => import('./pages/Quizs/QuizAttempted/QuizAttemptPage')
);
const AddQuizs = lazy(() => import('./pages/Quizs/CreateQuiz/AddQuizs'));

const ClientPage = lazy(() => import('./pages/Client/Client/ClientPage'));
const MyCommunityPage = lazy(() => import('./pages/Client/MyCommunityPage'));

const AppointmentBookingsPage = lazy(
  () => import('./pages/FinancialDoctor/AppointmentBookingsPage')
);

const BankLoanForm = lazy(() => import('./pages/MyLocker/BankLoanForm'));
const MyLockerHome = lazy(() => import('./pages/MyLocker/MyLockerHome'));
const BankLoanPage = lazy(() => import('./pages/MyLocker/BankLoanPage'));

const ConfigurationPage = lazy(() => import('./pages/Configuration'));
const ApplicationDetailsPage = lazy(
  () => import('./pages/Portfolio/ApplicationDetailsPage')
);
const LoginPage = lazy(() => import('./pages/LoginPage'));
const CampaignsPage = lazy(() => import('./pages/Campaigns'));
const CampaignDetailsPage = lazy(
  () => import('./pages/Campaigns/CampaignDetailsPage')
);
const CampaignReport = lazy(() => import('./pages/Campaigns/CampaignReport'));
const EntityList = lazy(() => import('./pages/EntityList'));
const AddEntityPage = lazy(() => import('./pages/EntityList/AddEntityPage'));
const EntityDetailsPage = lazy(
  () => import('./pages/EntityList/EntityDetailsPage')
);
const DashboardPage = lazy(() => import('./pages/Dashboard/DashboardPage'));
const PdfPage = lazy(() => import('./pages/LetterHead/PdfPage'));
const EmployeesPage = lazy(() => import('./pages/Employees/EmployeesPage'));
const AddUserPage = lazy(() => import('./pages/Employees/AddEmployeePage'));
const AddCandidatePage = lazy(() => import('./pages/Hrms/AddCandidatePage'));
const MarketingStrategiesPage = lazy(
  () => import('./pages/MarketingStrategies/MarketingStrategiesPage')
);
const AddMarketingStrategyPage = lazy(
  () => import('./pages/MarketingStrategies/AddMarketingStrategyPage')
);
const MarketingStrategyDetailsPage = lazy(
  () => import('./pages/MarketingStrategies/MarketingStrategyDetailsPage')
);
const FinancialDoctorPage = lazy(
  () => import('./pages/FinancialDoctor/Doctor/FinancialDoctorPage')
);
const BookingsPage = lazy(() => import('./pages/FinancialDoctor/BookingsPage'));

const LetterHead = lazy(() => import('./pages/LetterHead/LetterHead'));
const LetterHeadPage = lazy(() => import('./pages/LetterHead/LetterHeadPage'));
const LetterHeadDetailPage = lazy(
  () => import('./pages/LetterHead/LetterHeadDetailPage')
);
const UpdateLetterHead = lazy(
  () => import('./pages/LetterHead/UpdateLetterHead')
);
// const Dailyprogressreportpage = lazy(
//   () => import('./pages/Hrms/Dailyprogressreportpage')
// );
const CampainReportsPage = lazy(
  () => import('./pages/Reports/CampainReportsPage')
);
const ExecutiveDialerReportsPage = lazy(
  () => import('./pages/Reports/ExecutiveDialerReportsPage')
);
const CandidateListPage = lazy(() => import('./pages/Hrms/CandidateListPage'));
const AttendanceReportPage = lazy(
  () => import('./pages/Hrms/Attendance/AttendanceReportPage')
);
const DPRReportPage = lazy(() => import('./pages/Hrms/DPR/DPRReportPage'));
const LeaveManagementPage = lazy(
  () => import('./pages/Hrms/Leave/LeaveManagementPage')
);
const LeaveDecisionPage = lazy(
  () => import('./pages/Hrms/Leave/LeaveDecisionPage')
);

const MeetGeneratorReportsPage = lazy(
  () => import('./pages/Hrms/MeetGenerator')
);

const MeetDetails = lazy(
  () => import('./pages/Hrms/MeetGenerator/MeetDetails')
);

const PortfolioManagementReportPage = lazy(
  () => import('./pages/Portfolio/PortfolioManagementReportPage')
);
const COIReportPage = lazy(() => import('./pages/COI'));
const LeadRemarksPage = lazy(
  () => import('./pages/Hrms/Leads/LeadRemarksPage')
);
const BoosterKitsPage = lazy(
  () => import('./pages/Training/Kits/BoosterKitsPage')
);
const BoosterKitsPostsPage = lazy(
  () => import('./pages/Training/Posts/BoosterKitsPostsPage')
);

const AddKits = lazy(() => import('./pages/Training/Kits/AddKitsPage'));

const PostBoosterKits = lazy(
  () => import('./pages/Training/Posts/PostBoosterKits')
);

const Quiz = lazy(() => import('./pages/Training/Modules/QuizPage'));
const QuizReportPage = lazy(
  () => import('./pages/Training/Modules/QuizReportPage')
);
const ModulesDetailPage = lazy(
  () => import('./pages/Training/Modules/ModulesDetailPage')
);
const Quizquestion = lazy(
  () => import('./pages/Training/Modules/QuizQuestionPage')
);
const DriveInnPage = lazy(() => import('./pages/DriveInn/DriveInnPage'));
const DriveInnPostPage = lazy(
  () => import('./pages/DriveInn/Post/DriveInnPostPage')
);
const DriveInnPollPage = lazy(
  () => import('./pages/DriveInn/Poll/DriveInnPollPage')
);

const ApplicantsPage = lazy(() => import('./pages/Ops/ApplicantsPage'));
const ApplicationsPage = lazy(() => import('./pages/Ops/ApplicationsPage'));
const AddoperationPage = lazy(
  () => import('./pages/Ops/Operation/AddOperationPage')
);
const OperationdetailsPage = lazy(
  () => import('./pages/Ops/Operation/OperationDetailsPage')
);
const AirtelObdPage = lazy(() => import('./pages/AirtelObdPage'));

const CreditsPage = lazy(() => import('./pages/users/CreditsPage'));
const LeadForm = lazy(() => import('./pages/LeadForm'));
const AddProspectForm = lazy(() => import('./pages/MyHub/AddProspectForm'));
// const CommunityPage = lazy(() => import('./pages/MyHub/CommunityPage'));
// const AssociationsPage = lazy(() => import('./pages/MyHub/AssociationsPage'));
const CorporatePage = lazy(() => import('./pages/MyHub/CorporatePage'));
const BranchesPage = lazy(() => import('./pages/MyHub/BranchesPage'));
const AddBranchPage = lazy(() => import('./pages/MyHub/AddBranchPage'));
const BranchDetailsPage = lazy(() => import('./pages/MyHub/BranchDetailsPage'));
const LoanExperienceForm = lazy(() => import('./pages/loan-experience-form'));

const CreateContest = lazy(() => import('./pages/Contest/CreateContest'));
const AllContest = lazy(() => import('./pages/Contest/AllContest'));
const ContestDetail = lazy(() => import('./pages/Contest/_ContestDetail'));
const LeaderBoard = lazy(() => import('./pages/Contest/_LeaderBoard'));

const SurveyReportsPage = lazy(
  () => import('./pages/Reports/SurveyReportsPage')
);
const PushNotifications = lazy(() => import('./pages/PushNotifications'));
const ReferredList = lazy(() => import('./pages/ReferredList'));
const ClientReferredList = lazy(
  () => import('./pages/ReferredList/_ClientReferredList')
);
const ProspectReportsPage = lazy(
  () => import('./pages/Reports/ProspectReportsPage')
);
const EmployeePerformance = lazy(() => import('./pages/EmployeePerformance'));

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getAllConfiguration } = useConfigurations('serviceTypes');

  useEffect(() => {
    getAllConfiguration();
  }, [getAllConfiguration]);

  useEffect(() => {
    dispatch(setTablePagination({ page: 0, rowsPerPage: 10 }));

    // Clean up function (optional)
    return () => {
      // Clean up logic if needed
    };
  }, [dispatch, location]);

  return (
    <>
      <Loader />
      <ErrorMessage />
      <Routes>
        <Route path="/lead-form/:id" element={<LeadForm />} />
        <Route path="/lead-form" element={<LeadForm />} />
        <Route path="/my-hub/form/:id" element={<AddProspectForm />} />
        <Route path="/loan-experience" element={<LoanExperienceForm />} />
        <Route path="/loan-experience/:uid" element={<LoanExperienceForm />} />
      </Routes>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route element={<MainPage />}>
            <Route path="/" element={<DashboardPage />} />
            <Route
              path="/pl-eligibility-test"
              element={<PLEligibilityTest />}
            />
            <Route path="/profile" element={<DashboardPage />} />
            <Route path="/employees" element={<EmployeesPage />} />
            <Route path="/credits" element={<CreditsPage />} />
            <Route path="/add-employee" element={<AddUserPage />} />
            <Route path="/update-employee/:id" element={<AddUserPage />} />
            <Route path="/entities" element={<EntityList />} />
            <Route path="/add-entity" element={<AddEntityPage />} />
            <Route path="/entity/:id" element={<EntityDetailsPage />} />
            <Route path="/dialer-campaigns" element={<CampaignsPage />} />
            <Route
              path="/dialer-campaigns/:id"
              element={<CampaignDetailsPage />}
            />
            <Route
              path="/dialer-campaigns/reports/:id"
              element={<CampaignReport />}
            />
            <Route
              path="/marketing-strategies"
              element={<MarketingStrategiesPage />}
            />
            <Route
              path="/marketing-strategy/add"
              element={<AddMarketingStrategyPage />}
            />
            <Route
              path="/marketing-strategy/update/:id"
              element={<AddMarketingStrategyPage />}
            />
            <Route
              path="/marketing-strategies/details/:id"
              element={<MarketingStrategyDetailsPage />}
            />
            <Route
              path="/configurations/:type"
              element={<ConfigurationPage />}
            />
            <Route path="/letterhead/add" element={<LetterHead />} />
            <Route
              path="/letterhead/detail/:id"
              element={<LetterHeadDetailPage />}
            />
            <Route
              path="/letterhead/edit/:type/:id"
              element={<UpdateLetterHead />}
            />
            <Route path="/letterhead" element={<LetterHeadPage />} />
            <Route path="/pdf-page" element={<PdfPage />} />
            <Route path="/hrms/candidates" element={<CandidateListPage />} />
            <Route path="/hrms/add-candidate" element={<AddCandidatePage />} />
            {/* <Route
              path="/hrms/dailyprogressreport"
              element={<Dailyprogressreportpage />}
            /> */}
            <Route
              path="/reports/attendance-reports"
              element={<AttendanceReportPage />}
            />
            <Route
              path="/reports/leavemanagement-reports"
              element={<LeaveManagementPage />}
            />
            <Route
              path="/reports/leavemanagement-reports/:id"
              element={<LeaveDecisionPage />}
            />
            <Route
              path="/reports/leads-reports"
              element={<LeadManagementReportPage />}
            />
            <Route
              path="/reports/leads-reports/:id"
              element={<LeadRemarksPage />}
            />
            <Route
              path="/pms-reports/coi-reports"
              element={<COIReportPage />}
            />
            <Route
              path="/pms-reports/portfoliomanagement-reports"
              element={<PortfolioManagementReportPage />}
            />
            <Route
              path="/reports/portfoliomanagement-reports/:id"
              element={<ApplicationDetailsPage />}
            />
            <Route path="/reports/dpr-reports" element={<DPRReportPage />} />
            <Route
              path="/reports/campaign-reports"
              element={<CampainReportsPage />}
            />
            <Route
              path="/reports/executive-dialer-reports"
              element={<ExecutiveDialerReportsPage />}
            />
            <Route
              path="/reports/non-serviceable-pincodes"
              element={<NonServiceablePincodes />}
            />
            <Route
              path="/reports/prospect-reports"
              element={<ProspectReportsPage />}
            />
            <Route
              path="/reports/meet-generators-reports"
              element={<MeetGeneratorReportsPage />}
            />
            <Route
              path="/reports/meet-generators-reports/details/:id"
              element={<MeetDetails />}
            />
            <Route path="/training/boosterkits" element={<BoosterKitsPage />} />
            <Route
              path="/training/boosterposts"
              element={<BoosterKitsPostsPage />}
            />
            <Route path="/training/add-boosterkit" element={<AddKits />} />
            <Route
              path="/training/post-boosterkit"
              element={<PostBoosterKits />}
            />
            <Route path="/training/quiz" element={<Quiz />} />
            <Route
              path="/Training/QuizReportPage"
              element={<QuizReportPage />}
            />
            <Route
              path="/training/modulesDetail"
              element={<ModulesDetailPage />}
            />
            <Route path="/training/quizquestion" element={<Quizquestion />} />
            <Route
              path="/affiliate-requests"
              element={<FreelanceRequestPage />}
            />
            <Route path="/ops/applicants" element={<ApplicantsPage />} />
            <Route
              path="/ops/applicationsPage"
              element={<ApplicationsPage />}
            />
            <Route path="/ops/addoperation" element={<AddoperationPage />} />
            <Route
              path="/ops/OperationDetailsPage"
              element={<OperationdetailsPage />}
            />
            <Route path="/ops/all-operations" element={<OperationsPage />} />
            <Route path="/ops/growth-rates" element={<GrowthRatePage />} />
            <Route path="/ops/scalability" element={<ScalabilityPage />} />
            <Route path="/ops/over-view" element={<OverViewPage />} />
            <Route path="/ops/loans-summary" element={<LoansSummary />} />
            <Route
              path="/ops/loans-summary/:id"
              element={<ClientLoansDetail />}
            />
            <Route
              path="/ops/loans-summary/:id/invoice"
              element={<CreateInvoice />}
            />
            <Route
              path="/ops/loans-summary/:id/invoice/pdf-page"
              element={<InvoicePDF />}
            />

            <Route path="/quizs/create-quiz" element={<AddQuizs />} />
            <Route path="/quizs/quiz-posted" element={<QuizPostPage />} />
            <Route path="/quizs/quiz-attempted" element={<QuizAttemptPage />} />
            <Route path="/quizs/leaderBoard" element={<QuizLeaderBoard />} />

            <Route path="/digitalpart/idcard" element={<IDCardPage />} />
            <Route
              path="/digitalpart/visitingcard"
              element={<VisitingCardPage />}
            />
            <Route
              path="/digitalpart/marketingPoster"
              element={<MarketingPosterPage />}
            />
            <Route
              path="/financial-doctor/doctors"
              element={<FinancialDoctorPage />}
            />
            <Route
              path="/financial-doctor/bookings"
              element={<BookingsPage />}
            />

            <Route
              path="/financial-doctor/:id"
              element={<DoctorDetailsPage />}
            />

            <Route
              path="/financial-doctor/doctor/details/:id"
              element={<DoctorDetailsPage />}
            />
            <Route
              path="/financial-doctor/appointment-bookings"
              element={<AppointmentBookingsPage />}
            />
            <Route
              path="/financial-doctor/appointment-bookings/Detail/:id"
              element={<AppointmentBookingDetailPage />}
            />
            <Route
              path="/financial-doctor/appointment-bookings/status/:id"
              element={<AppointmentBookingStatusPage />}
            />

            <Route path="/client/list" element={<ClientPage />} />
            <Route path="/client/list/:id" element={<ClientDetailPage />} />
            <Route path="/client/my-community" element={<MyCommunityPage />} />
            <Route
              path="/client/my-community/preview"
              element={<CommunityPreviewPage />}
            />
            <Route
              path="/client/community-events"
              element={<ClientCommunityEventsPage />}
            />

            <Route path="/driveinn" element={<DriveInnPage />} />
            <Route path="/driveinn/posts" element={<DriveInnPostPage />} />

            <Route path="/driveinn/polls" element={<DriveInnPollPage />} />

            <Route path="/airtel-obd" element={<AirtelObdPage />} />
            <Route path="/push-notifications" element={<PushNotifications />} />
            <Route path="/referred-list" element={<ReferredList />} />
            <Route
              path="/referred-list/:id/clients"
              element={<ClientReferredList />}
            />

            {/* My Locker  */}
            <Route path="/my-locker" element={<MyLockerHome />} />
            <Route path="/bank-loan" element={<BankLoanPage />} />
            <Route path="/bank-loan-form" element={<BankLoanForm />} />
            <Route path="/myhub/:type" element={<CorporatePage />} />
            <Route path="/myhub/:type/:id" element={<BranchesPage />} />
            <Route
              path="/myhub/:type/:id/add-branch"
              element={<AddBranchPage />}
            />
            <Route
              path="/myhub/:type/:id/update-branch/:branch"
              element={<AddBranchPage />}
            />
            <Route
              path="/myhub/:type/:id/:branch"
              element={<BranchDetailsPage />}
            />
            <Route path="/contest/create-contest" element={<CreateContest />} />
            <Route path="/contest/all-contests" element={<AllContest />} />
            <Route path="/contest/:id" element={<ContestDetail />} />
            <Route path="/contest/:id/:quiz" element={<LeaderBoard />} />

            <Route
              path="/employee-performance"
              element={<EmployeePerformance />}
            />

            <Route
              path="/reports/survey-reports"
              element={<SurveyReportsPage />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
