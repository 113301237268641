import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
} from 'firebase/firestore';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setShowBackdrop, setShowSnackbar } from '../store/ui.store';
import { firestoreDb } from '../firebase';
import { useCallback } from 'react';
import { firebaseError } from '../firebase/utils';
import { InvoiceType, setInvoices } from '../store/invoice.store';

const useInvoice = () => {
  const dispatch = useAppDispatch();
  const { invoices } = useAppSelector((state) => state.invoice);

  const getInvoices = useCallback(async () => {
    if (invoices?.length !== 0) return;
    try {
      // dispatch(setShowBackdrop(true));
      const docSnap = await getDocs(query(collection(firestoreDb, 'invoice')));
      const invoiceDocs = docSnap.docs.map(
        (doc) =>
          ({
            uid: doc.id,
            ...doc.data(),
          } as InvoiceType)
      );
      dispatch(setInvoices(invoiceDocs));
    } catch (error) {
      dispatch(
        setShowSnackbar({
          open: true,
          msg: `${firebaseError(error)}`,
          type: 'error',
        })
      );
    } finally {
      // dispatch(setShowBackdrop(false));
    }
  }, [dispatch, invoices?.length]);

  const addInvoice = useCallback(
    async (data: InvoiceType) => {
      dispatch(setShowBackdrop(true));
      try {
        const invoiceRef = await addDoc(collection(firestoreDb, 'invoice'), {
          ...data,
          createdAt: serverTimestamp(),
        });

        const invoice = {
          ...data,
          uid: invoiceRef.id,
          createdAt: Timestamp.now(),
        };

        dispatch(setInvoices([...(invoices || []), invoice]));
      } catch (error) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: `${firebaseError(error)}`,
            type: 'error',
          })
        );
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    [dispatch, invoices]
  );

  return { addInvoice, getInvoices };
};

export default useInvoice;
