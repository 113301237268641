import {
  AccountBalance,
  AccountBox,
  Assessment,
  Badge,
  Campaign,
  Dashboard,
  ExitToApp,
  LocalHospital,
  ModelTraining,
  Pages,
  SettingsApplications,
  Tty,
  Person,
  PersonalVideo,
  Quiz,
  Hub,
  NotificationAdd,
  SettingsInputSvideo,
  Groups,
  EngineeringTwoTone,
} from '@mui/icons-material';

export const MENU_ITEMS = [
  { title: 'Dashboard', link: '/', icon: <Dashboard /> },
  {
    title: 'Employees',
    link: '/employees',
    icon: <Badge />,
    items: [
      { title: 'Employees', link: '/employees' },
      { title: 'Credits', link: '/credits' },
    ],
  },
  {
    title: 'Push Notifications',
    link: '/push-notifications',
    icon: <NotificationAdd />,
  },
  {
    title: 'Referred List',
    link: '/referred-list',
    icon: <Groups />,
  },
  { title: 'Letter Head', link: '/letterhead', icon: <Pages /> },
  { title: 'Entity List', link: '/entities', icon: <AccountBalance /> },
  {
    title: 'PL Eligibility Test',
    link: '/pl-eligibility-test',
    icon: <AccountBalance />,
  },
  {
    title: 'Marketing Strategies',
    link: '/marketing-strategies',
    icon: <Campaign />,
  },
  {
    title: 'Airtel OBD',
    link: '/airtel-obd',
    icon: <Campaign />,
  },
  { title: 'Dialer Campaigns', link: '/dialer-campaigns', icon: <Tty /> },
  {
    title: 'HRMS',
    link: '/hrms',
    icon: <AccountBox />,
    items: [{ title: 'Candidates List', link: '/hrms/candidates' }],
  },
  {
    title: 'Employee Performance',
    link: '/employee-performance',
    icon: <EngineeringTwoTone />,
  },

  {
    title: 'Reports',
    link: '/reports',
    icon: <Assessment />,
    items: [
      { title: 'Attendance Reports', link: '/reports/attendance-reports' },
      { title: 'DPR Reports', link: '/reports/dpr-reports' },
      {
        title: 'Leave Management Reports',
        link: '/reports/leavemanagement-reports',
      },
      { title: 'Campaign Reports', link: '/reports/campaign-reports' },
      {
        title: 'Executive Dialer Reports',
        link: '/reports/executive-dialer-reports',
      },
      {
        title: 'Non Serviceable Pincodes',
        link: '/reports/non-serviceable-pincodes',
      },
      { title: 'Survey Reports', link: '/reports/survey-reports' },
      {
        title: 'Prospect Reports',
        link: '/reports/prospect-reports',
      },
      {
        title: 'Converse',
        link: '/reports/meet-generators-reports',
      },
    ],
  },

  {
    title: 'Training',
    link: '/training',
    icon: <ModelTraining />,
    items: [
      { title: 'Booster Kits', link: '/training/boosterkits' },
      { title: 'Booster Posts', link: '/training/boosterposts' },
      // { title: 'Quiz', link: '/training/quiz' },
      // { title: 'Quiz Report', link: '/Training/QuizReportPage' },
    ],
  },
  {
    title: 'Affiliate Request',
    link: '/affiliate-requests',
    icon: <PersonalVideo />,
  },

  {
    title: 'Contest',
    link: '/contest',
    icon: <Quiz />,
    items: [
      { title: 'Create Contest', link: '/contest/create-contest' },
      { title: 'All Contests', link: '/contest/all-contests' },
    ],
  },

  {
    title: 'PMS Sales',
    link: '/pms-reports',
    icon: <Tty />,
    items: [
      {
        title: 'LMS Reports',
        link: '/pms-reports/portfoliomanagement-reports',
      },
      { title: 'COI Reports', link: '/pms-reports/coi-reports' },
    ],
  },
  {
    title: 'PMS Operations',
    link: '/ops',
    icon: <SettingsInputSvideo />,
    items: [
      { title: 'Operation', link: '/ops/OperationDetailsPage' },
      { title: 'View Operations', link: '/ops/all-operations' },
      { title: 'Growth Rate', link: '/ops/growth-rates' },
      { title: 'Scalability', link: '/ops/scalability' },
      { title: 'Over View', link: '/ops/over-view' },
      { title: 'Loans Summary', link: '/ops/loans-summary' },
    ],
  },
  {
    title: 'Financial Doctor',
    link: '/financial-doctor',
    icon: <LocalHospital />,
    items: [
      { title: 'Doctors', link: '/financial-doctor/doctors' },
      { title: 'Bookings', link: '/financial-doctor/bookings' },
      {
        title: 'Appointment Bookings',
        link: '/financial-doctor/appointment-bookings',
      },
    ],
  },
  {
    title: 'Client',
    link: '/client',
    icon: <Person />,
    items: [
      { title: 'Client List', link: '/client/list' },
      { title: 'My Community', link: '/client/my-community' },
      { title: 'Community Events', link: '/client/community-events' },
    ],
  },
  {
    title: 'Drive Inn',
    link: '/driveinn',
    icon: <Tty />,
    items: [
      { title: 'Posts', link: '/driveinn/posts' },
      { title: 'Vote-up', link: '/driveinn/polls' },
    ],
  },
  {
    title: 'My HUB',
    link: '/myhub',
    icon: <Hub />,
    items: [
      { title: 'Community', link: '/myhub/community' },
      { title: 'Nexus', link: '/myhub/nexus' },
      { title: 'Company', link: '/myhub/company' },
      // { title: 'All Hubs', link: '/myhub' },
    ],
  },
  {
    title: 'Configurations',
    link: '/configurations',
    icon: <SettingsApplications />,
    items: [
      { title: 'Roles', link: '/configurations/roles' },
      { title: 'Service Type', link: '/configurations/serviceTypes' },
      { title: 'Work Type', link: '/configurations/workTypes' },
      { title: 'Entity Type', link: '/configurations/entityTypes' },
      {
        title: 'Documents Checklist',
        link: '/configurations/checklistDocs',
      },
      { title: 'Source Type', link: '/configurations/source' },
      { title: 'Lead Status', link: '/configurations/leadStatus' },
      {
        title: 'Feedback Options',
        link: '/configurations/dialerFeedbackOptions',
      },
      { title: 'Leave Types', link: '/configurations/leaveTypes' },
      {
        title: 'Nature of Business',
        link: '/configurations/natureOfBusiness',
      },
      { title: 'Business Types', link: '/configurations/businessTypes' },
      { title: 'Surrogate', link: '/configurations/surrogate' },
    ],
  },
  { title: 'Profile', link: '/profile', icon: <AccountBox /> },
  { title: 'Logout', link: '/logout', icon: <ExitToApp /> },
];
