import { Image, Page, Text, View } from '@react-pdf/renderer';
import { InvoiceType } from '../../../store/invoice.store';
import { convertCurrencyToWordsIndianFormat } from '../../../utils/utils';
import { BoldText } from '../../LetterHead/Styles';
import Footer from '../../LetterHead/_Footer';
import { Header } from '../../LetterHead/_Header';
import { WaterMark } from '../../LetterHead/_WaterMark';

const formatDate = (dateInput: string) => {
  const date = new Date(dateInput);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

function calculateNinePercent(amount: number) {
  // Calculate 9% of the amount
  const ninePercent: number = (amount * 9) / 100;

  return { round: Math.round(ninePercent), ninePercent };
}

const formatAmount = (amount: number) => {
  // Round the amount to the nearest integer
  const roundedAmount = Math.round(amount);
  // Format the rounded amount as currency
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(roundedAmount);

  // Remove the ₹ if present
  return formattedAmount.replace('₹', '');
};

const InvoiceFormat = ({ data }: { data?: InvoiceType }) => {
  const totalAmount = Math.round(
    data?.items.reduce(
      (acc, item) => acc + parseFloat(item.payableAmount),
      0
    ) || 0
  );

  const percentAmount =
    totalAmount !== undefined ? calculateNinePercent(totalAmount).round : 0;

  const amountAfterTax = Math.round(
    totalAmount + percentAmount + percentAmount
  );

  return (
    <>
      <Page
        size={{ width: 590, height: 1000 }}
        // size={'A4'}
        wrap
        style={{
          paddingBottom: 90,
        }}
      >
        <Header />
        <WaterMark />
        <View>
          <BoldText
            style={{ textAlign: 'center', marginBottom: 10, color: '#3B5998' }}
          >
            TAX INVOICE
          </BoldText>
        </View>
        <View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              border: '1px solid black',
              marginHorizontal: 10,
              fontSize: 12,
            }}
          >
            <View style={{ width: '50%', display: 'flex', gap: 5, padding: 8 }}>
              <BoldText style={{ fontSize: 10, color: '#3B5998' }}>
                Customer Details
              </BoldText>
              <Text>To</Text>
              <Text>{data?.name}</Text>
              <Text>GST No : {data?.GSTNo}</Text>
              <Text>PAN No : {data?.PAN}</Text>
            </View>
            <View
              style={{
                borderLeft: '1px solid black',
                width: '50%',
                display: 'flex',
                gap: 5,
                padding: 8,
              }}
            >
              <Text>Invoice Date: {data?.date && formatDate(data?.date)}</Text>
              <Text>GST No: 36AALCB8898H1ZV</Text>
              <Text>PAN No : AALCB8898H</Text>
              <Text>Invoice No: {data?.invoiceNo}</Text>
              <Text>Reverse Charges: No</Text>
            </View>
          </View>
        </View>
        <View>
          {/* Invoice Items */}
          <View
            style={{
              marginTop: '20px',
              marginHorizontal: 10,
              border: '1px solid black',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                fontSize: 12,
                backgroundColor: '#e7f3d3',
              }}
            >
              <View
                style={{
                  width: '10%',
                  borderBottom: '1px solid black',
                  borderRight: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'center', marginTop: 4 }}>
                  Sr. No.
                </Text>
              </View>
              <View
                style={{
                  width: '45%',
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'center', marginTop: 4 }}>
                  Description of Services
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'center', marginTop: 4 }}>
                  Service Amount
                </Text>
              </View>
              <View
                style={{
                  width: '15%',
                  borderRight: '1px solid black',
                  borderBottom: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'center', marginTop: 4 }}>SAC</Text>
              </View>
              <View
                style={{
                  width: '25%',
                  padding: 5,
                  borderBottom: '1px solid black',
                }}
              >
                <Text style={{ textAlign: 'center', marginTop: 4 }}>
                  Payable Amount
                </Text>
              </View>
            </View>
            {/* Sample row, replace with actual data */}
            {data &&
              data.items.map((item, i) => (
                <View style={{ flexDirection: 'row', fontSize: 12 }} key={i}>
                  <View
                    style={{
                      width: '10%',
                      borderRight: '1px solid black',
                      padding: 5,
                      paddingBottom:
                        data.items.length === 1
                          ? 250
                          : i === data.items.length - 1
                          ? 40
                          : 5,
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{ textAlign: 'left' }}>{i + 1}</Text>
                  </View>
                  <View
                    style={{
                      width: '45%',
                      flexDirection: 'column',
                      gap: 5,
                      borderRight: '1px solid black',
                      padding: 5,
                      paddingBottom:
                        data.items.length === 1
                          ? 250
                          : i === data.items.length - 1
                          ? 40
                          : 5,
                    }}
                  >
                    <Text style={{ textAlign: 'left' }}>Service charge : </Text>
                    <Text style={{ textAlign: 'left' }}>
                      {item.description}
                    </Text>
                    <Text style={{ textAlign: 'left' }}>
                      DOJ : {item.doj && formatDate(item.doj)}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                      borderRight: '1px solid black',
                      padding: 5,
                      paddingBottom:
                        data.items.length === 1
                          ? 250
                          : i === data.items.length - 1
                          ? 40
                          : 5,
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{ textAlign: 'left' }}>
                      {formatAmount(Number(item.serviceAmount))}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: '15%',
                      borderRight: '1px solid black',
                      padding: 5,
                      paddingBottom:
                        data.items.length === 1
                          ? 250
                          : i === data.items.length - 1
                          ? 40
                          : 5,
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{ textAlign: 'left' }}>{`99715${i + 1}`}</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                      padding: 5,
                      paddingBottom:
                        data.items.length === 1
                          ? 250
                          : i === data.items.length - 1
                          ? 40
                          : 5,
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text style={{ textAlign: 'left' }}>
                      {formatAmount(Number(item.payableAmount))}
                    </Text>
                  </View>
                </View>
              ))}

            {/* Additional rows go here */}
            {/* Bottom Total */}
            <View
              style={{
                flexDirection: 'row',
                fontSize: 12,
                borderTop: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '10%',
                  borderRight: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'left' }}></Text>
              </View>
              <View
                style={{
                  width: '45%',
                  borderRight: '1px solid black',
                  padding: 5,
                  flexDirection: 'column',
                  gap: 5,
                  paddingVertical: 10,
                }}
              >
                <Text style={{ textAlign: 'left' }}>CGST Amount (9%) </Text>
                <Text style={{ textAlign: 'left' }}>SGST Amount (9%) </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  borderRight: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'left' }}></Text>
              </View>
              <View
                style={{
                  width: '15%',
                  borderRight: '1px solid black',
                  padding: 5,
                }}
              >
                <Text style={{ textAlign: 'left' }}></Text>
              </View>
              <View
                style={{
                  width: '25%',
                  padding: 5,
                  paddingVertical: 10,
                  flexDirection: 'column',
                  gap: 5,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text style={{ textAlign: 'left' }}>
                  {formatAmount(percentAmount)}
                </Text>
                <Text style={{ textAlign: 'left' }}>
                  {formatAmount(percentAmount)}
                </Text>
              </View>
            </View>

            {/* show in Total */}

            <View
              style={{
                flexDirection: 'row',
                fontSize: 12,
                borderTop: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '10%',
                  padding: 5,
                }}
              ></View>
              <View
                style={{
                  width: '85%',
                  padding: 5,
                  paddingVertical: 10,
                }}
              >
                <Text style={{ textAlign: 'left' }}>
                  Total Amount After Tax{' '}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  padding: 5,
                  marginTop: 5,
                }}
              >
                <Text style={{ textAlign: 'left' }}>
                  {formatAmount(amountAfterTax)}
                </Text>
              </View>
            </View>

            {/* show in Words */}

            <View
              style={{
                flexDirection: 'row',
                fontSize: 12,
                borderTop: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '10%',
                  padding: 5,
                }}
              ></View>
              <View
                style={{
                  width: '85%',
                  padding: 5,
                  gap: 5,
                  paddingVertical: 10,
                }}
              >
                <BoldText style={{ color: '#3B5998' }}>
                  Amount In words :
                </BoldText>
                <Text style={{ textAlign: 'left' }}>
                  {amountAfterTax !== undefined
                    ? convertCurrencyToWordsIndianFormat(amountAfterTax)
                    : ''}
                </Text>
              </View>
              <View
                style={{
                  width: '25%',
                  padding: 5,
                }}
              ></View>
            </View>

            {/* Bank Detail and Signature */}

            <View
              style={{
                flexDirection: 'row',
                fontSize: 12,
                borderTop: '1px solid black',
              }}
            >
              <View
                style={{
                  width: '60%',
                  padding: 20,
                  gap: 5,
                }}
              >
                <BoldText style={{ textAlign: 'left', color: '#3B5998' }}>
                  Bank Details :
                </BoldText>
                <Text style={{ textAlign: 'left' }}>
                  Current Account : 923020026639778
                </Text>
                <Text style={{ textAlign: 'left' }}>
                  Account name : BM INNOVATIONS PRIVATE LIMITED
                </Text>
                <Text style={{ textAlign: 'left' }}>Bank name : Axis Bank</Text>
                <Text style={{ textAlign: 'left' }}>
                  IFSC Code : UTIB0000061
                </Text>
              </View>
              <View
                style={{
                  width: '40%',
                  borderLeft: '1px solid black',
                  flexDirection: 'column',
                }}
              >
                <View
                  style={{
                    borderBottom: '1px solid black',
                    padding: 10,
                    flexDirection: 'column',
                    gap: 5,
                  }}
                >
                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}
                  >
                    <Text>Taxable Amount</Text>
                    <Text>{formatAmount(totalAmount)}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Total GST</Text>
                    <Text>{formatAmount(percentAmount + percentAmount)}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text>Taxable Amount</Text>
                    <Text>{formatAmount(amountAfterTax)}</Text>
                  </View>
                </View>
                <View
                  style={{
                    marginTop: 2,
                    marginLeft: -100,
                    padding: 5,
                    paddingVertical: 10,
                  }}
                >
                  <Image
                    src="/images/TimeStamp.jpg"
                    style={{
                      width: '400px',
                      height: '60px',
                      objectFit: 'contain',
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </>
  );
};

export default InvoiceFormat;
