import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { useCallback } from 'react';
import { firestoreDb } from '../firebase';
import { useAppDispatch } from '../store/hooks';
import {
  INonServiceableLocation,
  setLocations,
} from '../store/locations.store';
import { setShowBackdrop } from '../store/ui.store';
import useBatch from './useBatch';
import useReadExcelFile from './useReadExcelFile';

interface ILocation {
  pincode: number | string;
  city: string;
  state: string;
}

const useEntityLocations = (entityId: number) => {
  const dispatch = useAppDispatch();
  const readExcelFile = useReadExcelFile();
  const { writeBatchData } = useBatch();

  const uploadData = async (
    file: File,
    serviceType: string,
    surrogate?: string
  ) => {
    dispatch(setShowBackdrop(true));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uniqueNames: any = {};
    const jsonData = (await readExcelFile(file, false)) as ILocation[];
    // Remove duplicates
    const unique = jsonData.filter((obj) => {
      if (!uniqueNames[obj.pincode]) {
        uniqueNames[obj.pincode] = true;
        return true;
      }
      return false;
    });
    const collectionRef = collection(
      firestoreDb,
      `entities/${entityId}/locations/${serviceType}/${surrogate ?? 'data'}`
    );

    await writeBatchData(unique, collectionRef, 'pincode');
  };

  const nonServiceableLocations = useCallback(async () => {
    const q = query(
      collection(firestoreDb, 'non_serviceable_locations'),
      orderBy('createdAt', 'desc'),
      limit(25)
    );
    const querySnapshot = await getDocs(q);
    const _data = querySnapshot.docs.map(
      (doc) => doc.data() as INonServiceableLocation
    );
    dispatch(setLocations(_data));
  }, [dispatch]);

  return { uploadData, nonServiceableLocations };
};

export default useEntityLocations;
