import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const XLSX = require('xlsx');

const useReadExcelFile = () => {
  return useCallback(
    async (file: File, readAllShets?: boolean, sheetName?: string) => {
      return new Promise((resolve, reject) => {
        if (!file || !(file instanceof Blob)) {
          reject(new Error('Invalid file'));
          return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target?.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetNames = workbook.SheetNames as string[];
          if (readAllShets) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const obj: any = {};
            sheetNames.forEach((e) => {
              obj[e] = XLSX.utils.sheet_to_json(workbook.Sheets[e]);
            });
            resolve(obj);
          } else {
            const jsonData = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName ?? sheetNames[0]]
            );
            resolve(jsonData);
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    []
  );
};

export default useReadExcelFile;
