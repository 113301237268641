import { IExperience } from '../store/doctors.store';

export const formatDateTime = (date: Date | string) => {
  const formattedDateTime = new Intl.DateTimeFormat('en-IN', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(new Date(date));
  return formattedDateTime;
};

export const formatTime = (date: Date | string) => {
  const formattedTime = new Intl.DateTimeFormat('en-IN', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(new Date(date));
  return formattedTime;
};

export const formatDate = (date?: Date | string) => {
  if (!date) return '-';
  const formattedDateTime = new Intl.DateTimeFormat('en-IN', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(new Date(date));
  return formattedDateTime;
};

export const formattedDate = (date?: Date) => {
  if (!date) return '-';
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export function isValidDate(date: Date) {
  // Check if the created date is valid
  return !isNaN(new Date(date).getTime());
}

export const formatCurrency = (amount: number) => {
  const formattedCurrency = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(amount);

  return formattedCurrency;
};

export function convertCurrencyToWordsIndianFormat(amount: number) {
  const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
  ];
  const teens = [
    '',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ];
  const tens = [
    '',
    'Ten',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety',
  ];

  function convertBelowThousand(num: number) {
    if (num === 0) {
      return '';
    } else if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else {
      const digit = num % 10;
      const tenDigit = Math.floor(num / 10);
      return tens[tenDigit] + (digit !== 0 ? ' ' + units[digit] : '');
    }
  }

  function convertAmount(amount: number) {
    const crore = Math.floor(amount / 10000000);
    amount %= 10000000;

    const lakhs = Math.floor(amount / 100000);
    amount %= 100000;

    const thousands = Math.floor(amount / 1000);
    amount %= 1000;

    const hundreds = Math.floor(amount / 100);
    amount %= 100;

    const decimal = amount;

    let result = '';

    if (crore > 0) {
      result += convertBelowThousand(crore) + ' Crore ';
    }

    if (lakhs > 0) {
      if (result !== '') {
        result += ' ';
      }
      result += convertBelowThousand(lakhs) + ' Lakh ';
    }

    if (thousands > 0) {
      if (result !== '') {
        result += ' ';
      }
      result += convertBelowThousand(thousands) + ' Thousand ';
    }

    if (hundreds > 0) {
      if (result !== '') {
        result += ' ';
      }
      result += convertBelowThousand(hundreds) + ' Hundred ';
    }

    if (decimal > 0) {
      if (result !== '') {
        result += ' and ';
      }
      result += convertBelowThousand(decimal);
    }

    return result.trim();
  }

  // Check if the amount is within the supported range
  if (amount < 0 || amount >= 1000000000) {
    return 'Unsupported amount';
  }

  return convertAmount(amount);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeNullUndefined<T extends Record<string, any>>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        value !== '' &&
        value !== '' &&
        !Number.isNaN(value)
    )
  ) as T;
}

type ConvertableValues = 'Yes' | 'No' | boolean;

type ConvertYesNoToBoolean<T> = {
  [K in keyof T]: T[K] extends ConvertableValues ? boolean : T[K];
};

export function convertYesNoToBoolean<T>(obj: T): ConvertYesNoToBoolean<T> {
  const result: ConvertYesNoToBoolean<T> = {} as ConvertYesNoToBoolean<T>;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key] as ConvertableValues;
      result[key] =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value === 'Yes' ? true : value === 'No' ? false : (value as any);
    }
  }

  return result;
}

export const calculateExperienceDuration = (experiences: IExperience[]) => {
  if (!experiences || experiences.length === 0) {
    return '';
  }

  const currentDate = new Date();
  let totalMonths = 0;

  for (const experience of experiences) {
    const startDate = new Date(experience.startDate);
    const endDate = experience.isPresentCompany
      ? currentDate
      : new Date(experience.endDate);

    const diffMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      endDate.getMonth() -
      startDate.getMonth();

    totalMonths += Math.max(0, diffMonths); // Ensure positive difference
  }

  const totalYears = Math.floor(totalMonths / 12);
  const remainingMonths = totalMonths % 12;

  if (totalYears >= 1) {
    return `${totalYears} years ${remainingMonths} months`;
  } else {
    return `${remainingMonths} months`;
  }
};

export function capitalizeFirstLetter(str: string): string {
  // Check if the string is empty or null
  if (!str) return str;

  // Capitalize the first letter and concatenate it with the rest of the string
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function convertToShortForm(currencyString: string): string {
  const numericPart: number = parseFloat(
    currencyString.replace(/[^0-9.]/g, '')
  );

  if (isNaN(numericPart)) {
    return 'Invalid currency format';
  }

  if (numericPart < 1000) {
    return numericPart.toFixed(2);
  } else if (numericPart >= 10000000) {
    return (numericPart / 10000000).toFixed(2) + ' Cr';
  } else {
    return (numericPart / 100000).toFixed(2) + ' L';
  }
}

export function formatMobile(phoneNumber: string): string {
  // Check if the phone number starts with "+91"
  if (phoneNumber.startsWith('+91')) {
    // If it does, remove "+91" and return the rest of the number
    return phoneNumber.slice(3);
  }
  // If the phone number doesn't start with "+91", return it as is
  return phoneNumber;
}
