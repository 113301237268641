import { TableCell, TableRow } from '@mui/material';
import { useEffect } from 'react';
import TableWrapper from '../../components/TableWrapper';
import useEntityLocations from '../../hooks/useEntityLocations';
import { useAppSelector } from '../../store/hooks';

const headCells = [
  { id: 'entityId', label: 'Entity Id' },
  { id: 'pincode', label: 'Pincode' },
  { id: 'serviceType', label: 'Service Type' },
  { id: 'timestamp', label: 'Timestamp' },
];

const NonServiceablePincodes = () => {
  const { nonServiceableLocations } = useEntityLocations(0);
  const { page, rowsPerPage } = useAppSelector(
    (state) => state.ui.tablePagination
  );
  const data = useAppSelector((state) => state.locations.data);
  const entities = useAppSelector((state) => state.entity.entities);

  useEffect(() => {
    if (data.length === 0) {
      nonServiceableLocations();
    }
  }, [data.length, nonServiceableLocations]);

  return (
    <TableWrapper
      title="Non Serviceable Pincodes"
      headCells={headCells}
      data={data}
    >
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return (
            <TableRow
              hover
              tabIndex={-1}
              key={`${row.createdAt.toString()}-${row.entityId}`}
            >
              <TableCell>
                {entities.find((e) => e.id === row.entityId)?.name ??
                  row.entityId}
              </TableCell>
              <TableCell>{row.pincode}</TableCell>
              <TableCell>{row.serviceType}</TableCell>
              <TableCell>{row.createdAt.toDate().toLocaleString()}</TableCell>
            </TableRow>
          );
        })}
    </TableWrapper>
  );
};

export default NonServiceablePincodes;
