import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

interface ICoiReference {
  income: string;
  mobile: string;
  name: string;
  occupation: string;
  relation: string;
}

export interface ICoiTask {
  docId: string;
  createdAt: Timestamp;
  createdBy: string;
  existingLoan: string;
  income: number;
  loanType: string;
  location: string;
  mobile: string;
  name: string;
  occupation: string;
  reference: ICoiReference[];
  relation: string;
  remark: string;
  status: string;
  totalEmi: number;
  updatedAt?: Timestamp;
}

export interface LocationsState {
  coiTasks: ICoiTask[];
}

const initialState: LocationsState = {
  coiTasks: [],
};

export const coiTaskSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setCoiTasks(state, action: PayloadAction<LocationsState['coiTasks']>) {
      state.coiTasks = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCoiTasks } = coiTaskSlice.actions;

export default coiTaskSlice.reducer;
