import { collection, getDocs } from 'firebase/firestore';
import { IDepartment } from '../store/user.store';
import { firestoreDb } from './';
import { FirebaseError } from 'firebase/app';
import { FIREBASE_ERROR } from './ErrorCodes';

export const FIRESTORE_PATH = {
  serviceTypes: 'configuration/configuration/service_types',
  workTypes: 'configuration/configuration/work_types',
  entityTypes: 'configuration/configuration/entity_types',
  checklistDocuments: 'configuration/configuration/checklist_docs',
  source: 'configuration/configuration/source',
  leadStatus: 'configuration/configuration/lead_status',
  departments: 'configuration/configuration/departments',
  dialerFeedbackOptions: 'configuration/configuration/dialer_feedback_options',
  leaveTypes: 'configuration/configuration/leave_types',
  roles: 'configuration/configuration/roles',
  natureOfBusiness: 'configuration/configuration/nature_of_business',
  businessType: 'configuration/configuration/business_type',
  surrogate: 'configuration/configuration/surrogate',
  entities: 'entities',
  users: 'users',
  employees: 'employees',
  dialerCampaigns: 'dialer_campaigns',
} as const;

export const MAX_BATCH_SIZE = 500; // Maximum batch size for Firestore

export const getDepartments = async () => {
  const querySnapshot = await getDocs(
    collection(firestoreDb, FIRESTORE_PATH.departments)
  );
  return querySnapshot.docs.map((doc) => ({ ...doc.data() } as IDepartment));
};

export const firebaseError = (error: unknown) => {
  // eslint-disable-next-line no-console
  console.error('FIREBASE ERROR', error);
  return error instanceof FirebaseError
    ? FIREBASE_ERROR[error.code as keyof typeof FIREBASE_ERROR] ?? error.message
    : error;
};
