import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type MyHubType = 'company' | 'nexus' | 'community';

export interface IMyHub {
  docId: string;
  name: string;
  code: string;
  logo?: {
    type: string;
    url: string;
  };
  type: MyHubType;
  owner: {
    name: string;
    mobile: string;
  };
  bankDetails?: BankDetail;
  documents: {
    file?: File;
    title?: string;
    url?: string;
    type?: string;
  }[];
}

export interface IBranch {
  docId?: string;
  name: string;
  code: string;
  address: string;
  url: string;
  communicate: Communicate[];
  bankDetails?: BankDetail[] | null;
  sameAsMainBank?: boolean;
  documents: {
    file?: File;
    title?: string;
    url?: string;
    type?: string;
  }[];
  onboardedBy: string;
  assignees?: string[];
}

interface Communicate {
  name: string;
  designation: string;
  mobile: string;
}

interface BankDetail {
  nickname: string;
  accountHolderName: string;
  bankName: string;
  accountNo: string;
  ifscCode: string;
}

export interface LocationsState {
  community: IMyHub[];
  nexus: IMyHub[];
  company: IMyHub[];
  branches: IBranch[];
}

const initialState: LocationsState = {
  community: [],
  nexus: [],
  company: [],
  branches: [],
};

export const myhubSlice = createSlice({
  name: 'myhub',
  initialState,
  reducers: {
    setMyhub(
      state,
      action: PayloadAction<{
        type: MyHubType;
        data: LocationsState['community'];
      }>
    ) {
      if (action.payload.type === 'company')
        state.company = action.payload.data;
      if (action.payload.type === 'nexus') state.nexus = action.payload.data;
      if (action.payload.type === 'community')
        state.community = action.payload.data;
    },
    setBranches(state, action: PayloadAction<LocationsState['branches']>) {
      state.branches = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMyhub, setBranches } = myhubSlice.actions;

export default myhubSlice.reducer;
