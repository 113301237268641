import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface IConfiguration {
  id: string;
  name: string;
  status?: boolean;
  code?: string;
  surrogates?: string[];
}

export interface ConfigurationState {
  serviceTypes: IConfiguration[];
  workTypes: IConfiguration[];
  entityTypes: IConfiguration[];
  checklistDocs: IConfiguration[];
  source: IConfiguration[];
  leadStatus: IConfiguration[];
  dialerFeedbackOptions: IConfiguration[];
  leaveTypes: IConfiguration[];
  roles: IConfiguration[];
  natureOfBusiness: IConfiguration[];
  businessTypes: IConfiguration[];
  surrogate: IConfiguration[];
}

const initialState: ConfigurationState = {
  serviceTypes: [],
  workTypes: [],
  entityTypes: [],
  checklistDocs: [],
  source: [],
  leadStatus: [],
  dialerFeedbackOptions: [],
  leaveTypes: [],
  roles: [],
  natureOfBusiness: [],
  businessTypes: [],
  surrogate: [],
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setServiceTypes(
      state,
      action: PayloadAction<ConfigurationState['serviceTypes']>
    ) {
      state.serviceTypes = action.payload;
    },
    setWorkTypes(
      state,
      action: PayloadAction<ConfigurationState['workTypes']>
    ) {
      state.workTypes = action.payload;
    },
    setEntityTypes(
      state,
      action: PayloadAction<ConfigurationState['entityTypes']>
    ) {
      state.entityTypes = action.payload;
    },
    setChecklistDocs(
      state,
      action: PayloadAction<ConfigurationState['checklistDocs']>
    ) {
      state.checklistDocs = action.payload;
    },
    setSource(state, action: PayloadAction<ConfigurationState['source']>) {
      state.source = action.payload;
    },
    setLeadStatus(
      state,
      action: PayloadAction<ConfigurationState['leadStatus']>
    ) {
      state.leadStatus = action.payload;
    },
    setDialerFeedbackOptions(
      state,
      action: PayloadAction<ConfigurationState['dialerFeedbackOptions']>
    ) {
      state.dialerFeedbackOptions = action.payload;
    },
    setLeaveTypes(
      state,
      action: PayloadAction<ConfigurationState['leaveTypes']>
    ) {
      state.leaveTypes = action.payload;
    },
    setRoles(state, action: PayloadAction<ConfigurationState['roles']>) {
      state.roles = action.payload;
    },
    setNatureOfBusiness(
      state,
      action: PayloadAction<ConfigurationState['natureOfBusiness']>
    ) {
      state.natureOfBusiness = action.payload;
    },
    setBusinessTypes(
      state,
      action: PayloadAction<ConfigurationState['businessTypes']>
    ) {
      state.businessTypes = action.payload;
    },
    setSurrogate(
      state,
      action: PayloadAction<ConfigurationState['surrogate']>
    ) {
      state.surrogate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setServiceTypes,
  setWorkTypes,
  setEntityTypes,
  setChecklistDocs,
  setSource,
  setLeadStatus,
  setDialerFeedbackOptions,
  setLeaveTypes,
  setRoles,
  setNatureOfBusiness,
  setBusinessTypes,
  setSurrogate,
} = configurationSlice.actions;

export default configurationSlice.reducer;
