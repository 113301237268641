import { Button, MenuItem, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import InputField from '../../../components/InputField';
import TableWrapper from '../../../components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../store/hooks';

const headCells = [
  { id: 'sr.No', label: 'Sr.No' },
  { id: 'employeename(ID)', label: 'Employee Name (ID)' },
  { id: 'rm', label: 'R.M' },
  { id: 'leadName', label: 'Lead Name' },
  { id: 'servicetype', label: 'Service Type' },
  { id: 'mobileNo', label: 'Mobile No.' },
  { id: 'dealAmount', label: 'Deal Amount' },
  { id: 'locationPincode', label: 'Location (Pincode)' },
  { id: 'remarks', label: 'Remarks' },
];

const data = [
  {
    id: 1,
    srno: '1',
    employeenameid: 'Nikita Roy 515',
    rm: '2',
    leadName: 'NA',
    servicetype: 'NA',
    mobileNo: 'NA',
    dealAmount: 'NA',
    locationPincode: 'NA',
  },
  {
    id: 2,
    srno: '2',
    employeenameid: 'Sona 516',
    rm: '2',
    leadName: 'NA',
    servicetype: 'NA',
    mobileNo: 'NA',
    dealAmount: 'NA',
    locationPincode: 'NA',
  },
];

const LeadManagementPage = () => {
  const { page, rowsPerPage } = useAppSelector(
    (state) => state.ui.tablePagination
  );

  return (
    <TableWrapper
      title="Lead Management"
      headCells={headCells}
      data={data}
      toolbarChildren={
        <div className="w-full flex gap-5">
          <InputField
            id="search"
            label="Search"
            required
            placeholder="Search"
            onChange={() => {
              //
            }}
          />
          <InputField id="employee" label="Employee" select>
            {['Nikita', 'Komali', 'Sona'].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </InputField>
          <InputField
            id="startDate"
            label="Start Date"
            type="date"
            // value={startDate}
            onChange={() => {
              //
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <InputField
            id="endDate"
            label="End Date"
            type="date"
            // value={endDate}
            onChange={() => {
              //
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      }
    >
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          return (
            <TableRow hover tabIndex={-1} key={row.id}>
              <TableCell>{row.srno}</TableCell>
              <TableCell>{row.employeenameid}</TableCell>
              <TableCell>{row.rm}</TableCell>
              <TableCell>{row.leadName}</TableCell>
              <TableCell>{row.servicetype}</TableCell>
              <TableCell>{row.mobileNo}</TableCell>
              <TableCell>{row.dealAmount}</TableCell>
              <TableCell>{row.locationPincode}</TableCell>
              <TableCell>
                <Link to={`/reports/portfoliomanagement-reports/${row.id}`}>
                  <Button className="min-w-max" variant="outlined" size="small">
                    Lead Story
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          );
        })}
    </TableWrapper>
  );
};

export default LeadManagementPage;
