import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface INonServiceableLocation {
  entityId: number;
  pincode: string;
  serviceType: string;
  createdAt: Timestamp;
}

export interface LocationsState {
  data: INonServiceableLocation[];
}

const initialState: LocationsState = {
  data: [],
};

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocations(state, action: PayloadAction<LocationsState['data']>) {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLocations } = locationsSlice.actions;

export default locationsSlice.reducer;
