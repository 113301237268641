import { getAnalytics } from 'firebase/analytics';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
} as FirebaseOptions;

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebase);
export const auth = getAuth(firebase);
export const firestoreDb = initializeFirestore(firebase, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});
export const storage = getStorage(firebase);

export const fdFirestoreDb = initializeFirestore(
  firebase,
  {
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  },
  process.env.REACT_APP_FIREBASE_DATABASE_URL_DOCTOR
);
export const fdStorage = getStorage(
  firebase,
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DOCTOR
);

// Initialize Firebase for DriveInn
export const driveInnFirestoreDb = initializeFirestore(
  firebase,
  {
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  },
  process.env.REACT_APP_FIREBASE_DATABASE_URL_DRIVEINN
);
export const driveInnStorage = getStorage(
  firebase,
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_DRIVEINN
);

// if (process.env.NODE_ENV !== 'production') {
//   connectAuthEmulator(auth, 'http://127.0.0.1:9099');
//   connectFirestoreEmulator(firestoreDb, 'localhost', 8080);
// }
