import { Box, Button, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

interface PreviewTypes {
  image: FileList;
  eventName: string;
  eventLocation: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  eventDescription: string;
}

const CommunityPreviewPage = () => {
  const location = useLocation();
  const formData: PreviewTypes = location.state?.formData || {};
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    const loadImage = async () => {
      const file = formData.image[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    };
    loadImage();
  }, [formData.image]);

  const gradientStyle = {
    background: 'linear-gradient(to right, #3B5998, #86C421)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    fontWeight: 'bold',
    display: 'inline',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        width: '500px',
        marginX: 'auto',
      }}
    >
      <Box sx={{ borderRadius: '1rem', overflow: 'hidden' }}>
        <img
          src={imageSrc || ''}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          width: '100%',
          borderRadius: '1rem',
        }}
      >
        <Typography color={'#3B5998'}>{formData?.eventName}</Typography>
        <Typography fontWeight={'bold'}>Date: {formData?.eventDate}</Typography>
        <Typography fontWeight={'bold'}>
          Time : {formData?.startTime} to {formData?.endTime}
        </Typography>
        <Typography fontWeight={'bold'}>
          Venue : {formData?.eventLocation}
        </Typography>
        <Typography variant="body1">{formData?.eventDescription}</Typography>
        <Box textAlign={'right'}>
          <Box>
            <Typography style={gradientStyle}>Your&apos;s</Typography>
          </Box>
          <Box>
            <Typography style={gradientStyle}>Creator</Typography>
          </Box>
        </Box>
      </Card>
      <Button
        variant="contained"
        color="secondary"
        sx={{
          textTransform: 'capitalize',
          color: '#fff',
          paddingX: '2rem',
        }}
        size="large"
      >
        Publish
      </Button>
    </Box>
  );
};

export default CommunityPreviewPage;
