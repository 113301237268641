import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { DocumentData, Timestamp } from 'firebase/firestore';

export const LETTER_TYPE = [
  'Offer Letter',
  'Master Client Agreement',
  'Letter Of Intent',
  'Letter Of Termination',
  'Relieving Letter',
] as const;
export type LetterType = (typeof LETTER_TYPE)[number];

export const fileType =
  'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export interface OfferLetterProps {
  type: string;
  dateOfIssue: Date;
  dateOfJoining: Date;
  candidateName?: string;
  mobile?: string;
  mailID?: string;
  experience?: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    pincode: string;
    lat?: number;
    lng?: number;
  };
  role: string;
  designations: string[];
  department: string;
  workType: string;
  payType: string;
  currentCtc?: number;
  ctc: number;
  internshipDuration?: number;
  probationPeriod?: number;
  noticePeriod?: number;
  termination?: number;
  documents?: {
    panCard?: string;
    aadharCardFront?: string;
    aadharCardBack?: string;
    resume?: string;
    previousPaySlip?: string;
    experienceLetter?: string;
    bankStatement?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export interface ClientAgreement {
  type?: string;
  candidateName?: string;
  mobile?: string;
  mailID?: string;
  location: string;
  executionDay: string;
  executionMonth: string;
  executionYear: string;
  applicantName: string;
  fullName: string;
  address: string;
  serviceType: string;
  companyPersonName?: string;
  companyPersonDesignation?: string;
  companyEmail?: string;
  companySignature?: string;
  clientEmail?: string;
  clientOccupation: string;
  clientSignature?: string;
  clientType: string;
  consultationFees: string;
  timeLine: string;
  clientPan: string;
  paymentTerm: string;
  paymentTerm1: string;
  paymentTerm2: string;
  clientGST: string;
}

export type TProjectType =
  | 'Apartments'
  | 'Villas'
  | 'Independent Houses'
  | 'Commercial Space'
  | 'Open Plots';

export interface IProjectDetails {
  name: string;
  location: string;
  type: TProjectType;
  numberOfUnits: string;
  avgPricePerUnit: string;
  totalProjectCost: string;
  concernedPersonName: string;
  email: string;
  mobileNumber: string;
  designation: string;
  startDate: Date;
  possessionDate: Date;
  reraDocument: string;
  brochure: string;
  constructionApproval: string;
  projectPlan: string;
}

export interface LetterOfIntentProps {
  type: string;
  subType: string;
  joiningDate: Date;
  issueDate: Date;
  designation: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    pincode: string;
  };
  nameOfConcernedPerson: string;
  companyName?: string;
  associationName?: string;
  communityName?: string;
  branches?: string;
  headOffice?: string;
  personToCommute?: {
    name: string;
    designation: string;
    mobile: string;
    mailID: string;
    location: string;
  }[];
  registrationCertificate?: string;
  projects?: IProjectDetails[];
}

export interface TerminationProps {
  type: string;
  issueDate: Date;
  fromDate: Date;
  candidateName: string;
  employeeID: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}
export interface RelievingLetterProps {
  type: string;
  issueDate: Date;
  fromDate: Date;
  toDate: Date;
  candidateName: string;
  employeeID: string;
  position: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

// export interface ILetterHead {
//   offerLetter?: OfferLetterProps,
//   ClientAgreement?: ClientAgreement,
//   LetterOfIntent?: LetterOfIntentProps
// }

// export interface ILetterHead {
//   offerLetter?: OfferLetterProps,
//   ClientAgreement?: ClientAgreement,
//   LetterOfIntent?: LetterOfIntentProps
// }

export interface LettersState {
  letterType?: LetterType;
  offerLetterData?: OfferLetterProps;
  clientAgreementData?: ClientAgreement;
  letterOfIntentData?: LetterOfIntentProps;
  terminationData?: TerminationProps;
  relievingLetterData?: RelievingLetterProps;
  letterHeads?: DocumentData[];
}

const initialState: LettersState = {
  letterHeads: [],
};

export const letterSlice = createSlice({
  name: 'letters',
  initialState,
  reducers: {
    setLetterType(state, action: PayloadAction<LettersState['letterType']>) {
      state.letterType = action.payload;
    },
    setOfferLetterData(
      state,
      action: PayloadAction<LettersState['offerLetterData']>
    ) {
      state.offerLetterData = action.payload;
    },
    setClientAgreementData(
      state,
      action: PayloadAction<LettersState['clientAgreementData']>
    ) {
      state.clientAgreementData = action.payload;
    },
    setLetterOfIntentData(
      state,
      action: PayloadAction<LettersState['letterOfIntentData']>
    ) {
      state.letterOfIntentData = action.payload;
    },
    setLetterOfTerminationData(
      state,
      action: PayloadAction<LettersState['terminationData']>
    ) {
      state.terminationData = action.payload;
    },
    setRelievingLetterData(
      state,
      action: PayloadAction<LettersState['relievingLetterData']>
    ) {
      state.relievingLetterData = action.payload;
    },
    setLetterHeads(state, action: PayloadAction<LettersState['letterHeads']>) {
      state.letterHeads = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLetterType,
  setOfferLetterData,
  setClientAgreementData,
  setLetterOfIntentData,
  setLetterHeads,
  setLetterOfTerminationData,
  setRelievingLetterData,
} = letterSlice.actions;

export default letterSlice.reducer;
