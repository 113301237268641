import { User } from 'firebase/auth';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { auth } from '../firebase';

interface IAuthProvider {
  children: ReactNode;
}

export const AuthProvider: FC<IAuthProvider> = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (
      !window.location.pathname.includes('/lead-form') &&
      !window.location.pathname.includes('/my-hub/form') &&
      !window.location.pathname.includes('/loan-experience')
    ) {
      const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
        setUser(firebaseUser);
        if (!firebaseUser) {
          navigate('/login');
        }
      });

      return unsubscribe;
    }
  }, [navigate, setUser]);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
