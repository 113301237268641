import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

export interface IContestQuestion {
  docId?: string;
  question: string;
  information: string;
  options: string;
  answer: string;
  createdAt?: Timestamp;
}

export interface IContestQuestions {
  questions: IContestQuestion[];
}

export interface IContestQuiz {
  docId?: string;
  quizId?: string;
  quizNo?: string;
  startDateAndTime: Date;
  endDateAndTime: Date;
  timer: number;
}

export interface IContestForm {
  docId: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  prizes: {
    cost: number;
    image: string;
    name: string;
  }[];
  banner: string;
  quizInfo: IContestQuiz[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface LocationsState {
  contests: IContestForm[];
}

const initialState: LocationsState = {
  contests: [],
};

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setContests(state, action: PayloadAction<LocationsState['contests']>) {
      state.contests = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setContests } = contestSlice.actions;

export default contestSlice.reducer;
