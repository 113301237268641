import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

interface InputFieldProps {
  children?: ReactNode;
}

const InputField = forwardRef<
  HTMLInputElement,
  InputFieldProps & TextFieldProps
>(({ label, children, ...others }, ref) => (
  <TextField
    ref={ref}
    {...others}
    helperText={
      others.error
        ? others.helperText ??
          (label ? `${label} is invalid / required` : 'This is required!')
        : null
    }
    error={!!others.error}
    size="small"
    fullWidth
    label={label}
    variant="outlined"
  >
    {children}
  </TextField>
));

InputField.displayName = 'InputField';

export default InputField;
