import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentData, Timestamp } from 'firebase/firestore';
import { Edge, Node } from 'reactflow';
import {
  CourseType,
  ExamType,
  HigherEducation,
  InTake,
  Occupation,
  RentalType,
  VehicleMaker,
} from '../utils/Constants';

export interface ILocation {
  serviceTypes: number[];
  streetAddress: string;
  city: string;
  state: string;
  pinCode: string;
}

export interface ALLoan {
  vehicleMakers: VehicleMaker[];
  minModelYear: number;
  minOnRoadPrice: number;
  /** For Salaried */
  minSalary?: number;
  minAge?: number;
  ownHouse?: boolean;
  providentFund?: boolean;
  checkCompanyList?: boolean;
  /** For Self Employed */
  isBusinessRegistered?: boolean;
  isGST?: boolean;
  isITR?: boolean;
  /** For Rental Income */
  rentPaymentType?: 'Bank' | 'Cash' | 'Mixed';

  preferredWorkType: string;
}

export type PlSurrogates = 'Primary' | 'Secondary';

export type BlSurrogates =
  | 'Small Ticket'
  | 'Income Tax Return'
  | 'GST'
  | 'Professional Certificate';

export type AlSurrogates = 'New Loan' | 'Old Loan';
export type ElSurrogates = 'International' | 'Domestic';
export type LapSurrogates =
  | 'Plot Purchase'
  | 'Structured Purchase'
  | 'Small Plot Purchase'
  | 'Small Structured Purchase'
  | 'Plot + Construction'
  | 'Plot Mortgage'
  | 'Structured Mortgage'
  | 'Small Structured Mortgage'
  | 'Commercial Purchase'
  | 'Commercial Mortgage';
export type WclSurrogates = 'Micro' | 'MSME';

export interface Gst {
  newToCibil: boolean;
  minimumCibil: number;
  enquiriesAllowed: number;
  vintageYears: number;
  ownHouse: boolean;
  preferredWorkType: string;
  minGSTTurnover?: number;
  avgIndustryMargin?: number;
  minITRTurnover?: number;
  minNetProfit?: number;
  minABB?: number;
}

export interface ProfessionalCertificate {
  newToCibil: boolean;
  minimumCibil: number;
  officeSetup: boolean;
  minLinkedDocYears: number;
  qualifiedYears: number;
}

export interface Domestic {
  maxAge: number;
  coApplicant: boolean;
  coApplicantIncomeSource: Occupation[];
  coApplicantMinIncome: number;
  collateral: boolean;
  minCollateral: number;
  courseType: CourseType[];
  examType: ExamType[];
  offerLetter: boolean;
  higherEducation: HigherEducation[];
  minLoanAmount: number;
  maxLoanAmount: number;
  inTake: InTake[];
  preferredWorkType: string;
}

export interface CommercialMortgage {
  minArea: number;
  minimumCibil: number;
  newToCibil: boolean;
  minLinkedDocYears: number;
  balanceTransfer?: boolean;
  preferredWorkType: string;
  /** For Salaried */
  minimumSalary?: number;
  providentFund?: boolean;
  checkCompanyList?: boolean;
  /** For Self Employed */
  vintageYears: number;
  minITRTurnover?: number;
  minABB?: number;
  minGSTTurnover?: number;
  /** For Rental Income */
  rentalType?: RentalType[];
  rentPaymentType?: 'Bank' | 'Cash' | 'Mixed';
  rentalAgreement?: boolean;
}

export interface Pl {
  enquiriesAllowed: number;
  minimumCibil: number;
  minimumExperience: number;
  minimumSalary: number;
  newToCibil: boolean;
  preferredWorkType: string;
  providentFund: boolean;
  mcaVintage: number;
  professionalTax: boolean;
  officeVerification: string;
  bounces: string;
  settlement: string;
  tat: string;
  class: string[];
  paidUpCapital: number;
}

export interface Msme {
  minTurnover: number;
  minNetProfit: number;
  vintageYears: number;
  preferredWorkType: string;
}

export interface FileData {
  json: string;
  excel: string;
}

export interface IOpsDept {
  department_director?: boolean;
  hod?: boolean;
  manager?: boolean;
  team_lead?: boolean;
  executive?: boolean;
}

export interface Surrogates {
  PL?: string[];
  BL?: string[];
  AL?: string[];
  LAP?: string[];
  EL?: string[];
  WCL?: string[];
}

export interface IEntity {
  id: number;
  name: string;
  nickname: string;
  logo: string;
  serviceTypes: string[];
  checklist?: {
    PL?: Partial<Record<PlSurrogates, Pl>>;
    BL?: Partial<Record<BlSurrogates, Gst & ProfessionalCertificate>>;
    LAP?: Partial<
      Record<LapSurrogates, Partial<Record<Occupation, CommercialMortgage>>>
    >;
    WCL?: Partial<Record<WclSurrogates, Msme>>;
    AL?: Partial<Record<AlSurrogates, Partial<Record<Occupation, ALLoan>>>>;
    EL?: Partial<Record<ElSurrogates, Partial<Record<Occupation, Domestic>>>>;
  };
  createdAt: Timestamp;
  updatedAt: Timestamp;
  opsSelected?: Record<string, IOpsDept>;
  allowEligibilityChecks?: string[];
  enabledSurrogates?: Surrogates;
}

export interface AddEntity {
  id: number;
  name: string;
  nickname: string;
  logo: string;
  serviceTypes: string[];
  allowEligibilityChecks?: string[];
}

export interface IStaff {
  id: number;
  active: boolean;
  pincodes?: string[];
  serviceType: string;
  surrogates?: string[];
  occupation?: Occupation[];
  staffInfo: StaffInfo;
}

export interface StaffInfo {
  firstName: string;
  lastName: string;
  avatar?: string;
  empCode: string;
  gender: string;
  personalMail?: string;
  officialMail?: string;
  mobile: string;
  whatsAppMobile?: string;
  designation: string;
  city: string;
  state: string;
  reportingManager: ReportingManager;
}

export interface ReportingManager {
  name: string;
  mobile?: string;
  designation?: string;
}

export interface FlowData {
  nodes: Node[];
  edges: Edge[];
}

export interface IEntityQuestion {
  id: string;
  active: boolean;
  question: string;
  questionType: 'Text' | 'Dropdown';
  serviceType: string;
  surrogate?: string;
  occupation?: Occupation;
  options: string | null;
  required: boolean;
}

interface EntityState {
  entities: IEntity[];
  staff: IStaff[];
  status?: FlowData | null;
  questions: IEntityQuestion[];
  documents?: DocumentData;
}

const initialState: EntityState = {
  entities: [],
  staff: [],
  questions: [],
};

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntities(state, action: PayloadAction<EntityState['entities']>) {
      state.entities = action.payload;
    },
    setStaff(state, action: PayloadAction<EntityState['staff']>) {
      state.staff = action.payload;
    },
    setEntityStatus(
      state,
      action: PayloadAction<EntityState['status'] | undefined>
    ) {
      state.status = action.payload;
    },
    setEntityQuestions(state, action: PayloadAction<EntityState['questions']>) {
      state.questions = action.payload;
    },
    setEntityDocuments(state, action: PayloadAction<EntityState['documents']>) {
      state.documents = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEntities,
  setStaff,
  setEntityStatus,
  setEntityQuestions,
  setEntityDocuments,
} = entitySlice.actions;

export default entitySlice.reducer;
