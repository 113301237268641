import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from './campaign.store';
import configurationReducer from './configuration.store';
import dprReducer from './dpr.store';
import entityReducer from './entity.store';
import uiReducer from './ui.store';
import userReducer from './user.store';
import locationsReducer from './locations.store';
import marketingStrategiesReducer from './marketingStrategies.store';
import portfolioReducer from './portfolio.store';
import doctorsReducer from './doctors.store';
import driveInnReducer from './driveinn.store';
import lettersReducer from './letters.store';
import freelanceReducer from './freelanceRequests.store';
import leadsReducer from './leads.store';
import entityApplicationsReducer from './entityApplications.store';
import myhubReducer from './myhub.store';
import contestReducer from './contest.store';
import surveyReducer from './surveyReports';
import quizReducer from './quizs.store';
import prospectsReducer from './prospects.store';
import boosterKitsReducer from './boosterKits.store';
import invoiceReducer from './invoice.store';
import meetingsReducer from './meetings.store';
import coiTaskReducer from './coiTask.store';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    configuration: configurationReducer,
    ui: uiReducer,
    user: userReducer,
    campaign: campaignReducer,
    entity: entityReducer,
    dpr: dprReducer,
    locations: locationsReducer,
    marketingStrategies: marketingStrategiesReducer,
    portfolio: portfolioReducer,
    doctors: doctorsReducer,
    driveInn: driveInnReducer,
    letters: lettersReducer,
    freelanceRequests: freelanceReducer,
    leads: leadsReducer,
    entityApplications: entityApplicationsReducer,
    myhub: myhubReducer,
    contest: contestReducer,
    surveyReports: surveyReducer,
    quiz: quizReducer,
    prospects: prospectsReducer,
    boosterKits: boosterKitsReducer,
    invoice: invoiceReducer,
    meetings: meetingsReducer,
    coiTask: coiTaskReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
