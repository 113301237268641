import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ExpandableMenuItemProps {
  title: string;
  icon: ReactNode;
  items: {
    title: string;
    link: string;
  }[];
}
const ExpandableMenuItem = ({
  title,
  icon,
  items,
}: ExpandableMenuItemProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {items.map((item) => (
          <ListItem key={item.link} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          // <List key={item.link} disablePadding>
          //   <ListItemButton
          //     sx={{ pl: 4 }}
          //     onClick={() => {
          //       navigate(item.link);
          //     }}
          //   >
          //     <ListItemText primary={item.title} />
          //   </ListItemButton>
          // </List>
        ))}
      </Collapse>
    </>
  );
};

export default ExpandableMenuItem;
